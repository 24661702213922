
import { ENDPOINTS } from "../../constants";
import axios from "axios";
import { BACKEND_URL, PROJECTS } from "../../_configs";
// import projects from "../../_configs/projects_test.json";
import _ from 'lodash'

export const getProjects = async () => {
  try {

    let results = PROJECTS;
    // const response = await axios.get(
    //   `${BACKEND_URL}${ENDPOINTS.GET_PROJECTS}`
    // );

    // // console.log(result);
    // if (response.status === 200) {
    //   results = _.concat(response.data, PROJECTS) ;
    // }

    return results;

  } catch (error) {
    const response = error.response;

    console.log(response);
    //    return response

    return PROJECTS;
  }
}

export const getProject = async (contract) => {
  try {

    const project = PROJECTS.find(e => e.contract == contract);

    return {
      status: 200,
      data: project
    }

    // const result = await axios.get(
    //   `${BACKEND_URL}${ENDPOINTS.GET_PROJECT_DETAILS.replace(':id', contract)}`
    // );
    // if (result.status === 200) {
    //   return {
    //     status: 200,
    //     data: result.data
    //   }
    // } else {
    //   return {
    //     status: 404,
    //     data: null
    //   }
    // }

  } catch (error) {
    const response = error.response;

    console.log(response);

    return {
      status: 404,
      data: null
    }
  }
}

export const ACTION_CONST = {
    LANGUAGE_CHANGE: 'LANGUAGE_CHANGE',
    REQUEST_SUBMIT:'REQUEST_SUBMIT',
    REQUEST_DONE:'REQUEST_DONE',
    ALERT_SUCCESS: 'ALERT_SUCCESS',
    ALERT_FAILS: 'ALERT_FAILS',
    ALERT_WARNING: 'ALERT_WARNING',
    ALERT_CLEAR: 'ALERT_CLEAR',
    ALERT_LINK: 'ALERT_LINK',
    ALERT_LINK_CLEAR: 'ALERT_LINK_CLEAR',

    CONNECT_WALLET_SUCCESS:'CONNECT_WALLET_SUCCESS',
    LOG_OUT_WALLET_SUCCESS :'LOG_OUT_WALLET_SUCCESS',
    ENABLE_WALLET_SUCCESS: 'ENABLE_WALLET_SUCCESS',

    SET_SHOW_MODAL_HELP:'SET_SHOW_MODAL_HELP',
    

  


    GET_PROJECTS_SUCCESS:'GET_PROJECTS_SUCCESS',
    GET_LIST_CONTRACTS_INFO:'GET_LIST_CONTRACTS_INFO',
    GET_PROJECT_SELECTED: 'GET_PROJECT_SELECTED',
    SUBMIT_GET_PROJECTS:'SUBMIT_GET_PROJECTS',



    SUBMIT_PROJECT_SELECTED: 'SUBMIT_PROJECT_SELECTED',
    GET_PROJECT_SELECTED_SUCCESS: 'GET_PROJECT_SELECTED_SUCCESS',

    SET_CURRENT_CONTRACT_SELECTED: 'SET_CURRENT_CONTRACT_SELECTED',
    
    SET_LIST_CONTRACT_SELECTED: 'SET_LIST_CONTRACT_SELECTED',


    SET_JOB_PROJECT_SELECTED: 'SET_JOB_PROJECT_SELECTED',
    SET_JOB_GET_WALLET_INFO: 'SET_JOB_GET_WALLET_INFO',
    SET_JOB_COUNT_DOWN_OPEN: 'SET_JOB_COUNT_DOWN_OPEN',
    SET_JOB_COUNT_DOWN_CLOSE:'SET_JOB_COUNT_DOWN_CLOSE',
    SET_JOB_COUNT_DOWN_ROUND:'SET_JOB_COUNT_DOWN_ROUND',
    SET_JOB_COUNT_DOWN_FCFS_TIME:'SET_JOB_COUNT_DOWN_FCFS_TIME',

    SET_JOB_GET_KYC:'SET_JOB_GET_KYC',
    SET_JOB_GET_PROJECTS:'SET_JOB_GET_PROJECTS',

    CLEAR_INTERVAL_PROJECTS_JOB: 'CLEAR_INTERVAL_PROJECTS_JOB',
    GET_INFO_WALLET: 'GET_INFO_WALLET',

    GET_KYC_INFO:'GET_KYC_INFO',
    GET_KYC_3RD: 'GET_KYC_3RD',
    CLEAR_KYC_STATE: 'CLEAR_KYC_STATE',


    //merge staking
    SET_JOB_GET_BALANCE:'SET_JOB_GET_BALANCE',
    GET_STAKING_INFO:'GET_STAKING_INFO',
    SET_JOB_GET_STAKING_INFO: 'SET_GET_STAKING_INFO',
    GET_STAKING_WALLET_INFO:'GET_STAKING_WALLET_INFO',
    SET_JOB_GET_STAKING_WALLET_INFO: 'SET_GET_STAKING_WALLET_INFO',

    SET_JOB_COUNTDOWN_STAKE_TIME: 'SET_JOB_COUNTDOWN_STAKE_TIME',

    SUBMIT_GET_BALANCE: 'SUBMIT_GET_BALANCE',
    GET_BSCPAD_BALANCE: 'GET_BSCPAD_BALANCE',
    GET_BNB_BALANCE:'GET_BNB_BALANCE', 


    CLEAR_STAKING_JOB:'CLEAR_STAKING_JOB',

    SET_STAKING_ENABLE:'SET_STAKING_ENABLE',
    SET_JOB_GET_STAKING_STATUS:'SET_JOB_GET_STAKING_STATUS',

    SET_BLOCK_LATEST:'SET_BLOCK_LATEST'
}

import { get } from "lodash";
import { useSelector } from "react-redux";



export const useCloseProject = () => {
    return useSelector((state) => get(state, "project.closedProjects", []));
}

export const useWeb3Util = () => {
    return useSelector((state) =>
        get(state, "utils.web3Utils", null)
    );
}


export const useSelectedProject = () => {
    return useSelector((state) =>
        get(state, "project.selectedProject", null)
    );
}


export const useRoundInfo = () => {
    return useSelector((state) =>
        get(state, "project.selectedProject.infoRound", [])
    );
}

export const useIsConnectWallet = () => {
    return useSelector((state) =>
        get(state, "utils.isConnectWallet", false)
    );
}

export const useShowModalHelp = () => {
    return useSelector((state) =>
        get(state, "utils.showModalHelp", false)
    );
}

export const useLatestBlock = () => {
    return useSelector((state) => get(state, "utils.latestBlock", 0))
}
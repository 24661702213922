import React from 'react';
import { useTranslation } from 'react-i18next';
import TierRow from '../TierRow';

export default function TierContainer({ TIERED }) {
    const { t } = useTranslation();
    return (
        <>
            <div className="pp-home-how-it-work-content pp-section-body">
                <div className="container">
                    <div className="text-center mb-5">
                        <h3 className="text-uppercase" data-aos="fade-up" data-aos-delay="300"><b>{t('Round 1 - Allocation Round')}</b></h3>
                    </div>
                    <div className="row mb-3">
                        <TierRow tiered={TIERED["BRONZE"]} />
                        <TierRow tiered={TIERED["SILVER"]} />
                        <TierRow tiered={TIERED["GOLD"]} />
                        <TierRow tiered={TIERED["PLATINUM"]} />
                        <TierRow tiered={TIERED["DIAMOND"]} />
                        <TierRow tiered={TIERED["BLUE_DIAMOND"]} />
                    </div>
                    <p className="text-center">{t('In the first round, called the “Allocation Round”, users can purchase the amount allotted to them based on their tier.')}</p>

                    <div className="text-center mb-5 mt-5">
                        <h3 className="text-uppercase">< b>{t('Round 2 - FCFS ROUND')}</b></h3>
                    </div>

                    <div className="row mb-5 align-items-center">
                        <div className="col-md-6" data-aos="fade-up" data-aos-delay="100">
                            <img src="/images/gfx-e.png" className="w-100" />
                        </div>
                        <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                            <p dangerouslySetInnerHTML={{__html: t('In round 2, the unsold tokens from the first round are made available on a FCFS basis, only to guaranteed tiers (Platinum and above). These members can purchase an additional amount that is determined by a tier-based formula. This round is open until all tokens are sold, typically lasting for only a few minutes. After all the tokens are sold, the IDO is concluded.')}}></p>
                            <p className="mt-3"><b className="how-it-work-heading">{t('We will be collecting both data and feedback on the IDO structure in order to optimize the system over time as well as taking into consideration community feedback and potential DAO proposals.')}</b></p>
                            <p>{t('Our system is a predictable and provably fair system giving our users the proper incentives to accumulate and hold tokens and support each and every project launched.  Over time, we will tweak weights, add new tiers and change other parameters as necessary to keep the system functional, competitive and rewarding for all community members.')}</p>
                            <p>{t('$BSCPAD is the next evolution of blockchain launchpads solving the fundamental flaws that plague existing launchpads. This platform benefits all holders of the token and allows for fair launches giving traders of all sizes the opportunity to invest in the best upcoming Binance Smart Chain projects.')}</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TierRow({ tiered }) {
    const { t } = useTranslation();

    return (
        <>
            {
                tiered.type === 'lottery' &&
                <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                    <div className="pricing-table text-center">
                        <div className="row">
                            <div className="col-12">
                                <p className="bold price-header"><b className="text-warning">{t(tiered.level)}</b></p>
                            </div>
                            <hr />
                            <div className="col-12">
                                <p className="light thin mb-0">{t('Staking Requirement')}</p>
                            </div>
                            <div className="col-12">
                                <p className="bold price"><span>{tiered.amountStakingRequire}</span></p>
                            </div>

                            <div className="col-12">
                                <p className="light thin mb-0">{t('Staking Length Required')}</p>
                            </div>
                            <div className="col-12">
                                <p className="value price"><span>{t(tiered.stakingLengthRequire)}</span></p>
                            </div>

                            <div className="col-12">
                                <p className="light thin mb-0">{t('Whitelist Requirement Twitter')}</p>
                            </div>
                            <div className="col-12">
                                <p className="value price"><span>{t(tiered.tweetRequire)}</span></p>
                            </div>

                            <div className="col-12">
                                <p className="light thin mb-0">{t('Lottery Tickets ')}</p>
                            </div>
                            <div className="col-12">
                                <p className="value price"><span>{t(tiered.ticket)}</span></p>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            }
            {
                tiered.type === 'guaranteed' &&
                <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                    <div className="pricing-table text-center">
                        <div className="row">
                            <div className="col-12">
                                <p className="bold price-header"><b className="text-warning">{t(tiered.level)}</b></p>
                            </div>
                            <hr />
                            <div className="col-12">
                                <p className="light thin mb-0">{t('Staking Requirement')}</p>
                            </div>
                            <div className="col-12">
                                <p className="bold price"><span>{tiered.amountStakingRequire}</span></p>
                            </div>

                            <div className="col-12">
                                <p className="light thin mb-0">{t('Staking Length Required')}</p>
                            </div>
                            <div className="col-12">
                                <p className="value price"><span>{t(tiered.stakingLengthRequire)}</span></p>
                            </div>

                            <div className="col-12">
                                <p className="light thin mb-0">{t('Whitelist Requirement Twitter')}</p>
                            </div>
                            <div className="col-12">
                                <p className="value price"><span>{t(tiered.tweetRequire)}</span></p>
                            </div>

                            <div className="col-12">
                                <p className="light thin mb-0">{t('Guaranteed Allocation')}</p>
                            </div>
                            <div className="col-12">
                                <p className="value price"><span>{t(tiered.guaranteedAllocation)}</span></p>
                            </div>

                            <div className="col-12">
                                <p className="light thin mb-0">{t('Pool Weight')}</p>
                            </div>
                            <div className="col-12">
                                <p className="value price"><span>{t(tiered.poolWeight)}</span></p>
                            </div>
                            <hr />
                            {/* <div className="col-12">
                            <a href={`${tiered.uri}`} target="blank" className="btn btn-primary btn-lg">{t('Learn more')}</a>
                        </div> */}
                        </div>
                    </div>
                </div>
            }

        </>
    );
}

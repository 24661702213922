import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile } from "web3modal";
import { ACTION_CONST, ROUTES } from "../../../constants";
import { getKYC } from "../../../redux/services/kyc.api";
import { helpers } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

import exactMath from 'exact-math';
import {
  isMetamaskAvailable,
  isTrustWalletAvailable,
} from "../../../utils/utils";

import { changeLanguage } from "../../../redux/action/language";
import { useActiveWeb3React } from "../../../hook";

const Header = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [isBrowserMobile, setIsBrowserMobile] = useState(false)
  const { account, library } = useActiveWeb3React()
  const [isConnectWallet, setIsConnect] = useState(false)
  const showModalHelp = useSelector((state) => get(state, "utils.showModalHelp", false));

  const web3Utils = useSelector((state) => get(state, "utils.web3Utils", null));
  const stakedAmount = useSelector((state) => get(state, "wallet.stakingWalletInfo.stakedAmount", 0));
  const latestBlock = useSelector((state) => get(state, "utils.latestBlock", 0));
  const kycStatus = useSelector((state) => get(state, "wallet.kycStatus", null));
  const [bscpadBalance, setBscpadBalance] = useState(0);

  const [enableKYC, setEnableKYC] = useState(false)
  const language = useSelector((state) => get(state, "language.language", null));

  useEffect(() => {
    if (account) {
      setIsConnect(true)
    }
  }, [account])
  //set balance
  useEffect(() => {

    if (web3Utils && account) {


      //get bnb balance
      web3Utils.web3.eth.getBalance(account).then(balance => {

        dispatch({
          type: ACTION_CONST.GET_BNB_BALANCE,
          data: exactMath.div(balance, 10 ** 18)
        })
      }).catch(err => {
        console.log(err);
      })

      //get bscPad balance
      web3Utils.getBscpadBalance().then(data => {

        dispatch({
          type: ACTION_CONST.GET_BSCPAD_BALANCE,
          data: data
        })
        setBscpadBalance(data)
      })


    }

  }, [account, web3Utils, latestBlock]);




  const handleOnclickKyc = () => {
    getKYC(account, 'url').then(data => {
      if (data) {

        const url = data.url
        if (isMobile() && isMetamaskAvailable() || isTrustWalletAvailable())
          window.location.href = url
        else
          window.open(url, '_blank');
        // window.history.pushState("", "", url);
      }

    }).catch(err => {
      console.log(err);
    })
  }


  //check show hide status
  useEffect(() => {
    if (!account) {
      return;
    }
    if ((Number(bscpadBalance) + Number(stakedAmount)) >= 100.0) {

      setEnableKYC(true)

      getKYCAddress(account)

      const job = setInterval(() => {
        getKYCAddress(account)
      }, 30000)

      dispatch({
        type: ACTION_CONST.SET_JOB_GET_KYC,
        data: job
      })
    }
    // setEnableKYC(true)

  }, [account, bscpadBalance, stakedAmount])


  //get kyc
  const getKYCAddress = (address) => {
    getKYC(address, 'state').then(response => {
      address = address.toLowerCase()
      if (response) {

        const state = response.state
        console.log('state===>', state)
        console.log('address==>', address)

        if (state === 1) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'START'
          })
        }
        if (state === 2) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'PENDING'
          })
        }
        if (state === 3) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'APPROVED'
          })
        }
        if (state === 4) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'ERROR'
          })
        }
      }

    }).catch(err => {
      console.log(err);
    })
  }

  function toggleTheme() {
    if (document.body.classList.contains('darkmode')) {
      document.body.classList.remove('darkmode');
    } else {
      document.body.classList.add('darkmode');
    }
  }



  const handGotToProject = () => {
    dispatch({
      type: ACTION_CONST.CLEAR_INTERVAL_PROJECTS_JOB
    })
  }

  const handleChangeLanguage = (e, lang) => {
    e.preventDefault();
    dispatch(changeLanguage(lang));
  }

  return (
    <>
      <nav id="PPNavbar" className="navbar navbar-expand-md navbar-light bg-white">
        <div className="container">
          <Link className="navbar-brand d-flex align-items-center" to={ROUTES.HOMEPAGE}>
            <img src="/images/logo.png" height="27" alt="BSCPad" className="me-2" /> BSCPad
          </Link>

          <div className="d-flex align-items-center" style={{ flexWrap: 'nowrap' }}>
            {/* <Link className="nav-link btn btn-sm btn-outline-primary btn-circle me-3" to={ROUTES.CALENDAR}>
                  <i className="mdi mdi-calendar-range-outline"></i>
                </Link> */}
            <div className="dropdown d-block d-md-none">
              <button className="btn btn-lang btn-sm btn-outline-primary dropdown-toggle text-uppercase mw-72" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {language}
              </button>
              <ul className="dropdown-menu dropdown-menu-language dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a className={`dropdown-item ${language === 'en' ? 'active' : ''}`} href="#" onClick={(e) => handleChangeLanguage(e, 'en')}>
                    <img className="me-2" src="/images/united-states-of-america.png" width="20" />
                    <span>English</span>
                  </a>
                </li>
                <li>
                  <a className={`dropdown-item ${language === 'cn' ? 'active' : ''}`} href="#" onClick={(e) => handleChangeLanguage(e, 'cn')}>
                    <img className="me-2" src="/images/china.png" width="20" />
                    <span>Chinese</span>
                  </a>
                </li>
              </ul>
            </div>
            {isMobile() &&
              <div className="dropdown ms-3 d-block d-md-none d-lg-none d-xl-none">
                <button className="nav-link btn btn-sm btn-outline-primary btn-circle dropdown-toggle btn-helpmore" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="mdi mdi-help"></i>
                </button>
                <ul className="dropdown-menu  dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                  <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#helpModal" href="#">{t('How to connect wallet')}</a></li>
                  {isConnectWallet && <li><a className="dropdown-item" href="https://bscpad.medium.com/bscpad-kyc-process-16e6a5557138" target="_blank">{t('KYC Help')}</a></li>}
                </ul>
              </div>
            }
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className={isConnectWallet ? 'navbar-nav ms-auto mb-2 mb-md-0 connected' : 'navbar-nav ms-auto mb-2 mb-md-0'}>
              {
                !isConnectWallet ?
                  <li className="nav-item me-2">
                    <a className="btn btn-primary btn-sm" href="#" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"}>
                      <i className="mdi mdi-wallet-plus-outline me-1"></i>
                      <span>{t('Connect Wallet')}</span>
                    </a>
                  </li>
                  :
                  <>
                    <li className="nav-item me-2">
                      <a className="btn btn-primary btn-sm" href="#" data-bs-toggle="modal" data-bs-target="#walletModal">
                        <i className="mdi mdi-wallet-plus-outline me-1"></i>
                        <span>{helpers.formatTransactionHash(account, 8, 8)}</span> - <b>{helpers.formatNumberDownRoundWithExtractMax(bscpadBalance, 4)}</b> BSCPAD
                      </a>
                    </li>
                    {
                      enableKYC &&
                      <>
                        {
                          kycStatus === 'START' &&
                          <li className="nav-item me-2">
                            <button className="btn btn-warning btn-sm"
                              onClick={() => handleOnclickKyc()}
                              id="bnt-kyc-start">
                              <i className="mdi mdi-file-edit-outline me-1"></i>
                              <span>{t('KYC')}</span>
                            </button>
                          </li>
                        }
                        {
                          kycStatus === 'PENDING' &&
                          <li className="nav-item me-2">
                            <button className="btn btn-primary btn-sm"
                              onClick={() => handleOnclickKyc()}>
                              <i className="mdi mdi-clock-outline me-1"></i>
                              <span>{t('KYC')}</span>
                            </button>
                          </li>
                        }
                        {
                          kycStatus === 'APPROVED' &&
                          <>
                            <li className="nav-item me-2">
                              <button className="btn btn-success btn-sm"
                              // onClick={() => handleOnclickKyc()}
                              >
                                <i className="mdi mdi-check me-1"></i>
                                <span>{t('KYC')}</span>
                              </button>
                            </li>
                            <li className="nav-item me-2">
                              <button className="btn btn-outline-primary btn-sm"
                                data-bs-toggle="modal" data-bs-target="#setKycModal">
                                <img src="/images/tron-logo-crop.png" height="14" alt="TronPad" />
                                <span className="ms-1">{t('Sync KYC')}</span>
                              </button>
                            </li>
                          </>

                        }
                        {
                          kycStatus === 'ERROR' &&
                          <li className="nav-item me-2">
                            <button className="btn btn-danger btn-sm"
                              onClick={() => handleOnclickKyc()}
                            >
                              <i className="mdi mdi-close me-1"></i>
                              <span>{t('KYC')}</span>
                            </button>

                          </li>
                        }
                      </>
                    }
                  </>

              }
              <li className="nav-item me-2">
                <Link className="btn btn-outline-primary btn-sm btn-projects" aria-current="page" to={ROUTES.PROJECTS} onClick={handGotToProject}>
                  <i className="mdi mdi-fire me-1"></i>
                  <span>{t('Projects')}</span>
                </Link>
              </li>
              <li className="nav-item me-2">
                <Link className="btn btn-outline-primary btn-sm btn-staking" aria-current="page" to={ROUTES.STACKING} >
                  <i className="mdi mdi-blender-software me-1"></i>
                  <span>{t('Staking')}</span>
                </Link>
              </li>
              <li className="nav-item me-2">
                <Link className="nav-link btn btn-sm btn-outline-primary btn-circle" to={ROUTES.CALENDAR}>
                  <i className="mdi mdi-calendar-range-outline"></i>
                </Link>
              </li>
              <li className="nav-item me-2">
                <button className="nav-link btn btn-sm btn-outline-primary btn-circle" type="button" onClick={() => toggleTheme()}>
                  <i className="mdi mdi-lightbulb-on"></i>
                </button>
              </li>
              {
                !isMobile() && isConnectWallet &&
                <li className="nav-item d-none d-md-block">
                  <div className="dropdown">
                    <button className="nav-link btn btn-sm btn-outline-primary btn-circle dropdown-toggle btn-helpmore" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="mdi mdi-help"></i>
                    </button>
                    <ul className="dropdown-menu  dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                      <li><a className="dropdown-item" href="https://bscpad.medium.com/bscpad-kyc-process-16e6a5557138" target="_blank">{t('KYC Help')}</a></li>
                    </ul>
                  </div>
                </li>
              }
              <li className="nav-item d-md-block d-none">
                <div className="dropdown">
                  <button className="btn btn-lang btn-sm btn-outline-primary dropdown-toggle text-uppercase mw-72" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {language}
                  </button>
                  <ul className="dropdown-menu dropdown-menu-language dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a className={`dropdown-item ${language === 'en' ? 'active' : ''}`} href="#" onClick={(e) => handleChangeLanguage(e, 'en')}>
                        <img className="me-2" src="/images/united-states-of-america.png" width="20" />
                        <span>English</span>
                      </a>
                    </li>
                    <li>
                      <a className={`dropdown-item ${language === 'cn' ? 'active' : ''}`} href="#" onClick={(e) => handleChangeLanguage(e, 'cn')}>
                        <img className="me-2" src="/images/china.png" width="20" />
                        <span>Chinese</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;

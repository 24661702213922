import Web3 from "web3";

import erc20Abi from "../contracts/erc20.abi";
import abiContract from "../contracts/bscpad.json";
import stakingABIContract from "../contracts/staking.json";
import bscKycAbi from "../contracts/bsckyc.json";
import { calculateBalanceSend } from "./utils";
import { BigNumber } from "bignumber.js";
import {
  BSC_KYC_ADDRESS,
  STAKING_CONTRACT_ADDRESS,
} from "../_configs";
import { ACTION_STATUS } from "../constants";
// import exactMath from 'exact-math';

// console.log(BLOCKCHAIN_NETWORK);
export default class Web3Helper {
  

  constructor(provider, account) {
 
    this.web3 = new Web3(provider);
    this.address = account
  }

  getWeb3Helper (provider, account){
    if (!Web3Helper.web3) {
      Web3Helper.web3 = new Web3Helper(provider, account);
    }

    return Web3Helper.web3;
  } 

  async approve({ tokenContractAddress, contractAddress, amount }, callback) {
  
    // console.log("amount==>", amount);
    amount = calculateBalanceSend(amount);
    try {
      const tokenContract = new this.web3.eth.Contract(
        erc20Abi,
        tokenContractAddress
      );
      callback({
        status: ACTION_STATUS.APPROVING,
      });
      const amountInHex = "0x" + amount.toString(16);
      console.log(amountInHex);
      await tokenContract.methods
        .approve(contractAddress, amountInHex)
        .send({ from: this.address });
      // }
      callback({
        status: ACTION_STATUS.APPROVED,
      });
    } catch (error) {
      callback({
        status: ACTION_STATUS.APPROVE_FAILS,
      });
      console.log(error);
    }
  }

  async claim({ contractAddress, index }, callback) {
    
    const contract = new this.web3.eth.Contract(abiContract, contractAddress);

    try {
      const claimResult = await contract.methods
        .claim(index)
        .send({ from: this.address })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: ACTION_STATUS.CLAIM_FAIL,
          });
        })
        .then((receipt) => {
          if (receipt.status == true) {
            callback({
              status: ACTION_STATUS.CLAIM_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else callback({ status: ACTION_STATUS.CLAIM_FAIL });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: ACTION_STATUS.CLAIM_FAIL });
        });
      return claimResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: ACTION_STATUS.CLAIM_FAIL,
      });
      return e.message;
    }
  }

  async buyTokens({ contractAddress, tokenAddress, amount }, callback) {
  
    const contract = new this.web3.eth.Contract(abiContract, contractAddress);
    amount = calculateBalanceSend(amount);
    const amountInHex = "0x" + amount.toString(16);

    let sendObject;
    if (tokenAddress === "0x0000000000000000000000000000000000000000") {
      sendObject = { from: this.address, value: amountInHex };
    } else {
      sendObject = { from: this.address };
    }

    try {
      const boughtResult = await contract.methods
        .participate(tokenAddress, amountInHex)
        .send(sendObject)
        .on("error", (error) => {
          console.log(error);
          callback({
            status: ACTION_STATUS.BUY_FAIL,
          });
        })
        .then((receipt) => {
          if (receipt.status == true) {
            callback({
              status: ACTION_STATUS.BUY_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else callback({ status: ACTION_STATUS.BUY_FAIL });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: ACTION_STATUS.BUY_FAIL });
        });
      return boughtResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: ACTION_STATUS.BUY_FAIL,
      });
      return e.message;
    }
  }


  async getInfoAllocations(wallet) {
    const claimStatus = {
      0: "PENDING",
      1: "OPEN",
      2: "CLOSED",
    };
    const contract = new this.web3.eth.Contract(abiContract, wallet);
    const contractInfoAllocation = await contract.methods
      .infoAllocations()
      .call({ from: this.address });
    return _calculateAllowInfo(contractInfoAllocation)
    // let infoAllocation = [];
    // for (let i = 0; i < contractInfoAllocation[0].length; i++) {
    //   infoAllocation.push({
    //     no: contractInfoAllocation[0][i],
    //     allocationAmount: contractInfoAllocation[1][i],
    //     timestamp: parseInt(contractInfoAllocation[2][i]),
    //     claimedAmount: contractInfoAllocation[3][i],
    //     status: claimStatus[contractInfoAllocation[4][i]],
    //   });
    // }

    // return infoAllocation;
  }

  async getInfoWallet(wallet) {
    const tokenContract = new this.web3.eth.Contract(abiContract, wallet);
    const contractInfo = await tokenContract.methods
      .infoWallet()
      .call({ from: this.address });
    const bnbBalance = parseFloat(contractInfo[0] / 10 ** 18);
    const tokenBalance = parseInt(contractInfo[1]);
    const tier = contractInfo[2];
    const roundState = parseInt(contractInfo[3]);
    const roundStateText = contractInfo[4];
    const roundTimestamp = parseInt(contractInfo[5]);
    const remainingAllocation = parseInt(contractInfo[6]);
    const userParticipation = parseInt(contractInfo[7]);

    return {
      tokenBalance,
      tier,
      roundState,
      roundStateText,
      // roundStateString,
      roundTimestamp,
      remainingAllocation,
      bnbBalance,
      userParticipation,
    };
  }

  async getTokenBalance(tokenAddress) {
    const tokenContract = new this.web3.eth.Contract(erc20Abi, tokenAddress);

    const tokenBalance = await tokenContract.methods
      .balanceOf(this.address)
      .call();
    // return exactMath.div(tokenBalance, exactMath.pow(10, 18))

    return new BigNumber(tokenBalance.toString())
      .dividedBy(10 ** 18)
      .toFixed(18)
      .replace(/\.?0+$/, "")
      .toString();
  }

  async getAllowance(tokenAddress, contractAddress) {
    const tokenContract = new this.web3.eth.Contract(erc20Abi, tokenAddress);

    const allocationNumber = await tokenContract.methods
      .allowance(this.address, contractAddress)
      .call();
    // return exactMath.div(allocationNumber, exactMath.pow(10, 18))
    return new BigNumber(allocationNumber.toString())
      .dividedBy(10 ** 18)
      .toString();
    // return parseFloat(allocationNumber / 10 ** 18);
  }

  async getBscpadBalance() {
    const contract = new this.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS
    );
    const tokenAddress = await contract.methods.token().call();
    const bscpadBalance = await this.getTokenBalance(tokenAddress);
    return bscpadBalance;
  }

  async stakingDeposit({ amount }, callback) {

    const contract = new this.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS
    );
    // debugger
    // console.log("amount==>", amount);

    amount = calculateBalanceSend(amount);

    const amountInHex = "0x" + amount.toString(16);
    try {
      const depositResult = await contract.methods
        .stake(amountInHex)
        .send({ from: this.address })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: ACTION_STATUS.STAKING_DEPOSIT_FAIL,
          });
        })
        .on("transactionHash", (hash) => {
          callback({
            status: ACTION_STATUS.STAKING_DEPOSIT_SUBMIT,
            txID: hash,
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: ACTION_STATUS.STAKING_DEPOSIT_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else callback({ status: ACTION_STATUS.STAKING_DEPOSIT_FAIL });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: ACTION_STATUS.STAKING_DEPOSIT_FAIL });
        });
      return depositResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: ACTION_STATUS.STAKING_DEPOSIT_FAIL,
      });
      return e.message;
    }
  }

  //request withdraw staking
  async stakingInitiateWithdrawal({ amount }, callback) {

    const contract = new this.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS
    );
    amount = calculateBalanceSend(amount);
    const amountInHex = "0x" + amount.toString(16);
    try {
      const initiateWithdrawalResult = await contract.methods
        .unstake(amountInHex)
        .send({ from: this.address })
        .on("transactionHash", (hash) => {
          callback({
            status: "STAKING_INITIATE_WITHDRAWAL_SUBMIT",
            txID: hash,
          });
        })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: "STAKING_INITIATE_WITHDRAWAL_FAIL",
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: "STAKING_INITIATE_WITHDRAWAL_SUCCESS",
              txID: receipt.transactionHash,
            });
          } else callback({ status: "STAKING_INITIATE_WITHDRAWAL_FAIL" });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: "STAKING_INITIATE_WITHDRAWAL_FAIL" });
        });
      return initiateWithdrawalResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: "STAKING_INITIATE_WITHDRAWAL_FAIL",
      });
      return e.message;
    }
  }

  // execute withdraw staking
  async stakingExecuteWithdrawal(callback) {

    const contract = new this.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS
    );

    try {
      const executeWithdrawalResult = await contract.methods
        .withdraw()
        .send({ from: this.address })
        .on("transactionHash", (hash) => {
          callback({
            status: "STAKING_EXECUTE_WITHDRAWAL_SUBMIT",
            txID: hash,
          });
        })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: "STAKING_EXECUTE_WITHDRAWAL_FAIL",
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: "STAKING_EXECUTE_WITHDRAWAL_SUCCESS",
              txID: receipt.transactionHash,
            });
          } else callback({ status: "STAKING_EXECUTE_WITHDRAWAL_FAIL" });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: "STAKING_EXECUTE_WITHDRAWAL_FAIL" });
        });
      return executeWithdrawalResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: "STAKING_EXECUTE_WITHDRAWAL_FAIL",
      });
      return e.message;
    }
  }

  // execute withdraw rewards
  async stakingExecuteWithdrawRewards(callback) {

    const contract = new this.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS
    );

    try {
      const executeWithdrawRewardsResult = await contract.methods
        .withdrawRewards()
        .send({ from: this.address })
        .on("transactionHash", (hash) => {
          callback({
            status: "STAKING_EXECUTE_WITHDRAW_REWARDS_SUBMIT",
            txID: hash,
          });
        })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: "STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL",
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: "STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS",
              txID: receipt.transactionHash,
            });
          } else callback({ status: "STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL" });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: "STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL" });
        });
      return executeWithdrawRewardsResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: "STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL",
      });
      return e.message;
    }
  }

  async stakingRewards(callback) {

    const contract = new this.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS
    );

    try {
      // debugger
      const executeStakeRewardsResult = await contract.methods
        .stakeRewards()
        .send({ from: this.address })
        .on("transactionHash", (hash) => {
          callback({
            status: "STAKING_REWARDS_SUBMIT",
            txID: hash,
          });
        })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: "STAKING_REWARDS_FAIL",
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: "STAKING_REWARDS_SUCCESS",
              txID: receipt.transactionHash,
            });
          } else callback({ status: "STAKING_REWARDS_FAIL" });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: "STAKING_REWARDS_FAIL" });
        });
      return executeStakeRewardsResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: "STAKING_REWARDS_FAIL",
      });
      return e.message;
    }
  }

  async getStakingInfoWallet() {
    const contract = new this.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS
    );

    const infoWallet = await contract.methods.infoWallet(this.address).call();
    // infoWallet[0] = 1000*10**18
    // console.log("rewardAmount==>", BigNumber(infoWallet[5].toString()).dividedBy(10 ** 18).toFixed(18).replace(/\.?0+$/,"").toString());
    return {
      stakedAmount: BigNumber(infoWallet[0].toString())
        .dividedBy(10 ** 18)
        .toString(),
      unstakedAmount: BigNumber(infoWallet[1].toString())
        .dividedBy(10 ** 18)
        .toString(),
      depositTimestamp: Number(infoWallet[2]) * 1000,
      lastUnstakeTimestamp: Number(infoWallet[3]) * 1000,
      withdrawTimestamp: Number(infoWallet[4]) * 1000,
      rewardAmount: BigNumber(infoWallet[5].toString())
        .dividedBy(10 ** 18)
        .toFixed(18)
        .replace(/\.?0+$/, "")
        .toString(),
    };
  }
  //add set kyc
  async setKycTron(tronAddress, callback) {
    try {
      const contract = new this.web3.eth.Contract(bscKycAbi, BSC_KYC_ADDRESS);

      contract.methods
        .setKyc(tronAddress)
        .send({ from: this.address })
        .then((result) => {
          console.log(result);
          // console.log();
    
          callback({
            status: "SET_KYC_SUCCESS",
          });
        });
    } catch (error) {
      console.error(error.message);
      callback({
        status: "SET_KYC_FAIL",
      });
    }
  }

  async getKycAddress(){
    try {
      const contract = new this.web3.eth.Contract(bscKycAbi, BSC_KYC_ADDRESS);
      const address = await contract.methods.getKyc(this.address).call();
    
      return address;
    } catch (error) {
      console.log(error);
      return "0x0000000000000000000000000000000000000000"
    }
  }
}


const _calculateAllowInfo = (contractInfoAllocation)=>{
  console.log("contractInfoAllocation==>", contractInfoAllocation);
  let infoAllocation = [];
  const claimStatus = {
    0: "PENDING",
    1: "OPEN",
    2: "CLOSED",
  };
  const row1 = contractInfoAllocation[0]
  const row2 = contractInfoAllocation[1]
  const row3 = contractInfoAllocation[2]
  const row4 = contractInfoAllocation[3]
  const row5 = contractInfoAllocation[4]

    if(row1.length >=2){
     
      if((row1[0]^row1[1])=== 0 ){
        // console.log("row1[0]^row1[1] === 0==>", row1[0]^row1[1]);
        for (let i = 0; i < row1.length - 1; i++) {
         
          const item = row1[i]
          const nextItem = row1[i+1]


          if(item === nextItem){
          
            // console.log("next status==>", row5[i+1]);
            const allocationAmount = (row2[i] === row2[i+1])? row2[i]:`${row2[i]}-${row2[i+1]}`
            const timestamp = (row3[i] === row3[i+1])? row3[i]:`${row3[i]}-${row3[i+1]}`;
            const percentage = row4[i+1]

            infoAllocation.push({
              no: item,
              allocationAmount,
              timestamp,
              claimedAmount: row4[i],
              status: claimStatus[row5[i]],
              percentage
            })
          }
        }

        return {
          layout: 2,
          infoAllocation
        };
      }
    }

    for (let i = 0; i < row1.length; i++) {
      infoAllocation.push({
        no: row1[i],
        allocationAmount: row2[i],
        timestamp: parseInt(row3[i]),
        claimedAmount: row4[i],
        status: claimStatus[row5[i]],
      });
    }
    
  return {
    layout: 1,
    infoAllocation,
  }
}
import React, { useCallback, useEffect, useState } from 'react';
import { actAlertMsgWarning } from '../../redux/action';
import { useDispatch } from 'react-redux';
import { ACTION_CONST, connectors } from '../../constants';
import { isMobile } from 'web3modal';
import { useTranslation } from 'react-i18next';
import { connectorLocalStorageKey, ConnectorNames } from '../literals';
import useAuth from '../../hook/useAuth';
import { useActiveWeb3React } from '../../hook';
import Web3Helper from '../../utils/useWeb3Utils'
// import { getKYC } from '../../redux/services/kyc.api'



const ConnectWalletModal = (props) => {
    const { t } = useTranslation();
    // const wallet = useWallet();
    const dispatch = useDispatch();
    //show hide button
    const { login } = useAuth();
    const { account, library, error } = useActiveWeb3React()

    const handleConnectClick = useCallback((walletConfig) => {
        try {
            login(walletConfig.connectorId);
            window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
        } catch (error) {
            console.log(error);
        }

        // onDismiss()
    }, [])

    useEffect(() => {

        if (error)
            return;

        if (account && library && library.provider) {
            const web3 = new Web3Helper(library.provider, account)

            dispatch({
                type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
                data: web3
            })
            return;
        }

    }, [library, account, error])



    return (
        <>
            <div className="modal fade" id="connectWalletModal" tabIndex="-1" aria-labelledby="connectWalletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="connectWalletModalLabel">{t('Connect to wallet')}</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            {connectors.map((entry, key) => {
                                if (entry.enable === true) {
                                    return (
                                        <div key={key} data-bs-dismiss="modal"
                                            onClick={() => handleConnectClick(entry)}
                                            id={`wallet-connect-${entry.title.toLocaleLowerCase()}`}
                                            className="c-list border-b px-3 py-2 d-flex align-items-center"
                                        >
                                            <img src={entry.icon} width="30px" className="me-2" alt="bscpad" />
                                            <div className="text-white">{entry.title}</div>
                                        </div>
                                    )
                                }

                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConnectWalletModal;



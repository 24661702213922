export const extensionName = {
  metamask: "METAMASK",
  binanceExtension: "BINANCE_EXTENSION",
  trustWallet: "TRUST_WALLET",
};


/// TIERED SYSTEM
export const TIERED_SYSTEM = {
  BRONZE: {
    level:'Bronze',
    amountStakingRequire: `1000`,
    stakingLengthRequire: `5AM UTC on IDO opening day`,
    tweetRequire: `Like, Comment & Retweet`,
    guaranteedAllocation:`Yes`,
    ticket:`1`,
    uri:`https://bscpad.medium.com/bscpad-tiered-ido-model-89b630f6372e`,
    type:'lottery'
  },
  SILVER: {
    level:'Silver',
    amountStakingRequire: `2500`,
    stakingLengthRequire: `5AM UTC on IDO opening day`,
    tweetRequire: `Like, Comment & Retweet`,
    guaranteedAllocation:`Yes`,
    ticket:`3`,
    uri:`https://bscpad.medium.com/bscpad-tiered-ido-model-89b630f6372e`,
    type:'lottery'
  },
  GOLD: {
    level:`Gold`,
    amountStakingRequire: `5000`,
    stakingLengthRequire: `5AM UTC on IDO opening day`,
    tweetRequire: `Like, Comment & Retweet`,
    guaranteedAllocation:`Yes`,
    ticket:`7`,
    uri:`https://bscpad.medium.com/bscpad-tiered-ido-model-89b630f6372e`,
    type:'lottery'
  },
  PLATINUM: {
    level:'Platinum',
    amountStakingRequire: `10000`,
    stakingLengthRequire: `5AM UTC on IDO opening day`,
    tweetRequire: `None`,
    guaranteedAllocation:`Yes`,
    poolWeight:`10`,
    uri:`https://bscpad.medium.com/bscpad-tiered-ido-model-89b630f6372e`,
    type:'guaranteed'
  },
  DIAMOND: {
    level:'Diamond',
    amountStakingRequire: `25000`,
    stakingLengthRequire: `5AM UTC on IDO opening day`,
    tweetRequire: `None`,
    guaranteedAllocation:`Yes`,
    poolWeight:`30`,
    uri:`https://bscpad.medium.com/bscpad-tiered-ido-model-89b630f6372e`,
    type:'guaranteed'
  },
  BLUE_DIAMOND: {
    level:'Blue Diamond',
    amountStakingRequire: `75000`,
    stakingLengthRequire: `5AM UTC on IDO opening day`,
    tweetRequire: `None`,
    guaranteedAllocation:`Yes`,
    poolWeight:`60 + Private Allocations`,
    uri:`https://bscpad.medium.com/bscpad-tiered-ido-model-89b630f6372e`,
    type:'guaranteed'
  },
  
};


//ADVISORS
export const ADVISORS = {
  // JASPER_BYUN:{
  //   name:'JASPER BYUN',
  //   image:'images/JASPER_BYUN.jpeg',
  //   position:'Founder \n \r at Blocksync Ventures',
  //   modal:`#team-popup-1`,
  //   telegram:null,
  //   twitter:null,
  //   linked:null,
  // },
  LESTER_LIM:{
    name:'LESTER LIM',
    image:'images/LESTER_LIM.jpeg',
    position:'Founder at X21 Digital',
    modal:`#team-popup-2`,
    telegram:null,
    twitter:null,
    linked:null
  },
  IAN_FRIEND:{
      name:'IAN FRIEND',
      image:'images/Ian_Friend.jpeg',
      position:'Co-Founder and COO at Ferrum Network',
      modal:`#team-popup-3`,
      telegram:null,
      twitter:null,
      linked:null
  },
  DANISH_CHAUDHRY:{
      name:'DANISH CHAUDHRY',
      image:'images/Danish_Chaudhry.jpg',
      position:'CEO at Bitcoin.com Exchange – Entrepreneur, Startup Advisor, Mentor and Investor',
      modal:`#team-popup-4`,
      telegram:null,
      twitter:null,
      linked:null
  },
  EXNETWORK_CAPITAL:{
    name:'EXNETWORK CAPITAL',
    image:'images/exntc.png',
    position:'Exnetwork Capital is an investment firm focused on funding and incubating blockchain projects.',
    modal:`#team-popup-5`,
    telegram:null,
    twitter:null,
    linked:null
  },
  TIM_FROST:{
    name:'TIM FROST',
    image:'images/TIM_FROST.jpg',
    position:'CEO and Co-founder \r of YIELD App',
    modal:`#team-popup-6`,
    telegram:null,
    twitter:null,
    linked:null
  }

}

export const LEGAL_PARTNER ={
  image:'images/SilkLegal_main_horizontal.png',
  description:`Silk Legal is a boutique law firm specializing in FinTech and Cryptocurrency.  We combine a deep understanding of blockchain technology with an expert knowledge of international regulations to analyze issues, risks and opportunities. Silk Legal is a proud member of Global Digital Finance, the leading global association of digital asset companies advocating for and accelerating the adoption of best practices for digital assets.`
}
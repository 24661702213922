import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CONST, MESSAGES } from "../../constants";
import { get } from "lodash";
const TronWeb = require('tronweb')
const tronWeb = new TronWeb({
    fullHost: "https://api.trongrid.io"
}
)

const SetKYCModal = (props) => {
    const dispatch = useDispatch();

    const showBlockUI = useSelector((state) => get(state, "utils.blocking", false));
    const web3Utils = useSelector((state) => get(state, "utils.web3Utils", null));
    const [kycAddress, setKycAddress] = useState("")
    const [tronAddress, setTronAddress] = useState("");
    const [enableBtn, setEnableBtn] = useState(false);
    const [step, setStep] = useState(2);
    const [syncAddressSubmit, setSyncAddressSubmit] = useState(0)

    const [errorInputTrx, setErrorInputTrx] = useState(true)
    useEffect(() => {
        if (web3Utils) {
            web3Utils.getKycAddress().then(addressInHexFormat => {
                if (addressInHexFormat !== "0x0000000000000000000000000000000000000000" && addressInHexFormat !== "") {
                  
                    const addressBase58 = tronWeb.address.fromHex(addressInHexFormat);
                    console.log(addressBase58);
                    setKycAddress(addressBase58)
                    setStep(1)
                }else{
                    setStep(2)
                }

            })
        }
    }, [web3Utils, showBlockUI])


    const handleSyncKyc = () => {
        if (!web3Utils) {
            return;
        }
        dispatch({
            type: ACTION_CONST.REQUEST_SUBMIT
        })
        const tronAddressHex = convertTronToBscAddress(tronAddress)
        web3Utils.setKycTron(tronAddressHex, (result) => {
            if (result.status == "SET_KYC_SUCCESS") {
                setEnableBtn(false)
                dispatch({ type: ACTION_CONST.REQUEST_DONE })
                dispatch({
                    type: ACTION_CONST.ALERT_SUCCESS,
                    message: MESSAGES.SYNC_KYC_SUCCESS
                })
                setStep(1)
            }
            if (result.status == "SET_KYC_FAIL") {
                setEnableBtn(false)
                dispatch({ type: ACTION_CONST.REQUEST_DONE })
                dispatch({
                    type: ACTION_CONST.ALERT_FAILS,
                    message: MESSAGES.SYNC_KYC_FAIL
                })
                setStep(2)
                
            }
        })
    }

    const handleOnchangeAddress = (e) => {
        const tronAddress = e.target.value

        if (tronWeb.isAddress(tronAddress)) {
            setTronAddress(tronAddress)
            setEnableBtn(true)
            setErrorInputTrx(false)
        } else {
            setEnableBtn(false);
            setErrorInputTrx(true)
            // if not tron address add red border input 
        }
    }

    const handleClose = ()=>{
        if (kycAddress !== "0x0000000000000000000000000000000000000000" && kycAddress !== "") {
            setStep(1)
        }else{
            setStep(2)
        }

    }

    return (
        <>
            <div className="modal fade " id="setKycModal" tabIndex="-1" aria-labelledby="walletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="walletModalLabel">Synchronize TRONPad KYC</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">

                                {step === 2 &&
                                    <div>
                                        <p>Please input your TRON wallet address below to automatically synchronize your KYC approved status to participate in IDOs on TRONPad.</p>
                                        <div className="p-m-warning d-flex">
                                            <i className="fas fa-info-circle "></i>
                                            <div>
                                                After Synchronizing, please wait up to 5-10 minutes for your KYC status to update on TRONPad. 
                                                If you have any issues please contact <a className="text-warning" href="mailto:support@bscpad.com"><span>Support</span></a>.
                                            </div>
                                        </div>
                                        <input type="text" className="input-tron-address form-control ms-2 me-1" placeholder="Input your TRON Address" autoFocus onChange={(e) => handleOnchangeAddress(e)} />
                                    </div>
                                }
                                {/* {step === 1 &&
                                    <div className="tex-center" style={{ textAlign: "center" }}>
                                        <p className="mb-2"> Do you want to synchronize this address to TRONPad KYC?</p>
                                        <p className="text-warning">{tronAddress}</p>
                                    </div>
                                } */}
                                {step === 1 &&
                                    <div className="tex-center" style={{ textAlign: "center" }}>
                                        <p className="mb-2">The TRON address below is currently synchronized to your BSCPAD KYC status.</p>
                                        <p className="mb-2">Do you wish to update this address?</p>
                                        <p className="text-warning">{kycAddress}</p>
                                    </div>
                                }
                            </div>
                            {
                                step === 2 &&
                                <div className="text-center mt-4 py-3">
                                    <button disabled={!enableBtn} className="btn btn-primary" onClick={handleSyncKyc} data-bs-dismiss="modal">Synchronize</button>
                                </div>
                            }
                            {/* {step === 1 &&
                                <div className="text-center mt-4 py-3">
                                    <button disabled={!enableBtn} className="btn btn-primary" onClick={() => { setStep(2) }} >&nbsp;&nbsp;&nbsp;Next&nbsp;&nbsp;&nbsp;</button>
                                </div>
                            } */}
                            {
                                step === 1 &&
                                <div className="text-center mt-4 py-3">
                                    <button className="btn btn-primary" onClick={() => { setStep(2) }} >&nbsp;&nbsp;&nbsp;Update&nbsp;&nbsp;&nbsp;</button>
                                </div>
                            }





                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SetKYCModal;


export function convertTronToBscAddress(tronAddress) {
    const hexString = tronWeb.address.toHex(tronAddress)
    const bscAddress = '0x' + hexString.substring(2)
    return bscAddress.toLowerCase()
}
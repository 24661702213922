import React, { useEffect, useState } from "react";
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { ACTION_CONST, ACTION_STATUS } from "../../constants";
import { addTokenToMetamask } from '../../utils/metamaskUtils'
import { isMetamaskAvailable, isTrustWalletAvailable } from "../../utils/utils";
import { formatTimeStampAllocation, formatTokenAllocation, helpers } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { useWeb3Util } from "../../hook/useSate";

const YourAllocationComponent = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const web3Utils = useWeb3Util()
    const [layout, setLayout] = useState(1);
    const [allocationInfo, setAllocation] = useState([])


    useEffect(() => {
        if (props) {
            setAllocation(props.allocationInfo)
            setLayout(props.layout)
        }
    }, [props])

    const handleClaimClick = (index) => {
        if (web3Utils) {
            dispatch({
                type: ACTION_CONST.REQUEST_SUBMIT
            })

            web3Utils.claim({
                contractAddress: props.contractAddress,
                index: index
            }, (data) => {

                if (data.status == ACTION_STATUS.CLAIM_SUCCESS) {

                    dispatch({ type: ACTION_CONST.REQUEST_DONE })

                    dispatch({
                        type: ACTION_CONST.ALERT_SUCCESS,
                        message: "Tokens Successfully Claimed"
                    })
                    props.handleBuyClick()

                }
                if (data.status == ACTION_STATUS.CLAIM_FAIL) {
                    dispatch({ type: ACTION_CONST.REQUEST_DONE })
                    dispatch({
                        type: ACTION_CONST.ALERT_FAILS,
                        message: "Failed to claim tokens, Please contact support"
                    })
                    props.handleBuyClick()
                }
                //code handle event claim
            })
        }
    }

    const handleAddTokenToMetamask = async () => {
        if (props.tokenAddress) {
            addTokenToMetamask({
                tokenAddress: props.tokenAddress,
                tokenSymbol: props.tokenSymbol,
                tokenDecimals: props.decimals,
                tokenImage: ""
            }, (res) => {
                if (res.status == "ADD_TOKEN_SUCCESS") {
                    dispatch({
                        type: ACTION_CONST.ALERT_SUCCESS,
                        message: "Successfully added token to MetaMask"
                    })
                }
                if (res.status == "ADD_TOKEN_FAILS") {
                    dispatch({
                        type: ACTION_CONST.ALERT_FAILS,
                        message: "Failed to add token to MetaMask"
                    })
                }
            })
        } else {
            dispatch({
                type: ACTION_CONST.ALERT_FAILS,
                message: "Token incorrect!"
            })
        }

    }

    return (
        <>

            <div className="tab-pane fade" id="allocation" role="tabpanel" aria-labelledby="allocation-tab">

                <div className="py-3">
                    <div className="row">
                        <div className="col-md-9 pe-md-0">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table mb-0 pp-table-info">
                                            <tbody>
                                                <tr className="card-header" style={{ border: "none" }}>
                                                    <td>
                                                        {t('No.')}
                                                    </td>
                                                    <td>
                                                        {t('Allocation')}
                                                    </td>
                                                    {
                                                        layout === 2 &&
                                                        <td>
                                                            {t('Percentage')}

                                                        </td>
                                                    }
                                                    <td>
                                                        {t('Date')}
                                                    </td>
                                                    <td>
                                                        {t('Claimed')}
                                                    </td>
                                                    {isMetamaskAvailable() &&
                                                        <td>
                                                            {t('Action')}
                                                        </td>
                                                    }

                                                </tr>
                                                {
                                                    (allocationInfo.length > 0 && layout === 1) &&
                                                    allocationInfo.map((item, key) => (
                                                        <tr key={key}>
                                                            <td>{item.no}</td>
                                                            <td>{helpers.formatNumberDownRoundWithExtractMax((item.allocationAmount / 10 ** props.decimals || 0), 4)} {props.tokenSymbol}</td>
                                                            <td>{item.timestamp !== 0 ?
                                                                <div>{moment(item.timestamp * 1000 || 0).utc().format('YYYY-MM-DD HH:mm:ss')} UTC </div>
                                                                : t("DEX Listing")
                                                            }</td>
                                                            <td>{helpers.formatNumberDownRoundWithExtractMax((item.claimedAmount / 10 ** props.decimals || 0), 4)} {props.tokenSymbol}</td>
                                                            <td><button className="btn btn-primary btn-sm"
                                                                onClick={() => handleClaimClick(key)}
                                                                disabled={!(item.status === "OPEN")}>{t('Claim Tokens')}</button></td>
                                                        </tr>
                                                    ))

                                                }
                                                {
                                                    (allocationInfo.length > 0 && layout === 2) &&
                                                    allocationInfo.map((item, key) => (
                                                        <tr key={key}>
                                                            <td>{item.no}</td>
                                                            <td>{formatTokenAllocation(item.allocationAmount, props.decimals, 4)}</td>
                                                            <td>{(item.percentage / 100).toFixed(2)}%</td>
                                                            <td>{(item.timestamp !== 0 && item.timestamp !== "0") ?
                                                                <div dangerouslySetInnerHTML={{ __html: formatTimeStampAllocation(item.timestamp) }}></div>
                                                                : t("DEX Listing")
                                                            }</td>

                                                            <td>{helpers.formatNumberDownRoundWithExtractMax((item.claimedAmount / 10 ** props.decimals || 0), 4)}</td>
                                                            <td><button className="btn btn-primary btn-sm"
                                                                onClick={() => handleClaimClick(key)}
                                                                disabled={!(item.status === "OPEN")}>{t('Claim Tokens')}</button></td>
                                                        </tr>
                                                    ))

                                                }

                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 text-md-end">
                            <div className="mt-2">
                                {
                                    props.allocationInfo.length >= 0 &&

                                    <button onClick={() => handleAddTokenToMetamask()} className="btn btn-primary mt-2 text-nơwrap" style={{width:'240px'}}>
                                        <i className="fas fa-plus me-2"></i>
                                        <span dangerouslySetInnerHTML={{ __html: t('Add token to <b>MetaMask</b>') }}></span>
                                    </button>
                                }
                            </div>
                          
                            <div className="mt-2">
                                {

                                    props.claim &&
                                    <a className="btn btn-primary ms-2 text-nơwrap" href={props.claim} target="blank" style={{width:'240px'}}>
                                        {t('Claim via Project site')}
                                    </a>

                                }
                            </div>



                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default YourAllocationComponent;

import React from "react";
import { useTranslation } from "react-i18next";
import AdvisorsContainer from "../../component/AdvisorContainer";

import { ADVISORS, LEGAL_PARTNER } from '../../constants/values'



const AdvisorComponent = (props) => {
    const { t } = useTranslation();

    return (

        <div className="pp-home-advisors pp-section py-3" id="advisor">
            {/* <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-lg-6">
                        <div className="section-head">
                            <h2 className="title title-xl" data-aos="fade-up" data-aos-delay="100" title={t('ADVISORS')}>{t('ADVISORS')}</h2>
                            <p data-aos="fade-up" data-aos-delay="200" className="mt-3">{t('Our mentors are a great part of our team.')}</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <AdvisorsContainer advisors={ADVISORS} legalPartner={LEGAL_PARTNER} />
        </div>

    );
};

export default AdvisorComponent;

const projects = require('./projects.json')

module.exports = {
  // BACKEND_URL: "https://bscpad.myfantasy.cloud",
  // BACKEND_URL: 'https://t.nftlaunch.network',

  //product config
  BACKEND_URL:'https://bscpad.com', //product
  BLOCKCHAIN_NETWORK: "MAINNET",
  BSC_EXPLORER: "https://bscscan.com",
  BSC_NODE_URL: [
    "https://bsc-dataseed.binance.org/",
    "https://bsc-dataseed1.defibit.io/",
    "https://bsc-dataseed1.ninicoin.io/",
    "https://bsc-dataseed3.binance.org/",
    "https://bsc-dataseed4.defibit.io/",
    "https://bsc-dataseed3.ninicoin.io/",
  ],
  BSCPAD_ADDRESS:'0x5A3010d4d8D3B5fB49f8B6E57FB9E48063f16700', //product
 
  STAKING_CONTRACT_ADDRESS: "0x100e9DFb6F42E19935df75eF616A63FEEC2EF4CF", //product

  BSC_KYC_ADDRESS : '0xC3E818F91b62Aa54B9a27F0f90721cF73558104E',
  PROJECTS: projects
}
import React from "react";
import moment from 'moment'
import { useTranslation } from "react-i18next";
const ScheduleTabComponent = (props) => {
  const { t } = useTranslation();
  return (
    <div className="tab-pane fade" id="schedule" role="tabpanel" aria-labelledby="schedule-tab">
      <div className="py-3">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table mb-0 pp-table-info">
                    <tbody>
                      <tr className="card-header" style={{ border: "none" }}>
                        <td>{t('Round')}</td>
                        <td>{t('Opens')}</td>
                        <td>{t('Closes')}</td>
                      </tr>
                      {props.roundInfo.length > 0 &&
                        props.roundInfo.map((item, key) =>
                        (
                          <tr key={key}>
                            <td>{item.round}<br /></td>
                            <td>{moment(item.opens * 1000 || 0).utc().format('YYYY-MM-DD HH:mm:ss')} UTC</td>
                            <td>{moment(item.closes * 1000 || 0).utc().format('YYYY-MM-DD HH:mm:ss')} UTC</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleTabComponent;

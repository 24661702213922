import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
const TermsOfUsePage = (props) => {
  return (
    <>
      <div className="container py-5">
        <div className="d-flex align-items-start tab-term">
          <div className="nav nav-pills-policy flex-column nav-pills me-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <Link to={ROUTES.TERMS_OF_USE} className="nav-link text-start active" id="v-pills-terms-tab">Terms of Use </Link>
            <Link to={ROUTES.PRIVACY_POLICY} className="nav-link text-start" id="v-pills-privacy-policy-tab">Privacy Policy</Link>
            <Link to={ROUTES.COOKIE_POLICY} className="nav-link text-start" id="v-pills-cookies-policy-tab">Cookies Policy</Link>
            <Link to={ROUTES.STAKING_POLICY} className="nav-link text-start" id="v-pills-staking-policy-tab">Staking Policy</Link>
            <Link to={ROUTES.CLICKWRAP_ACCEPTANCE} className="nav-link text-start" id="v-pills-clickwrap-acceptance-tab">Clickwrap Acceptance</Link>
          </div>
          <div className="tab-content" id="v-pills-policy-tabContent">
            <div className="tab-pane fade show active" id="v-pills-cookies-policy" role="tabpanel" aria-labelledby="v-pills-cookies-policy-tab">
              <>
                <div className="text-center mb-3">
                  <p><strong>BSCP DIGITAL LIMITED</strong></p>
                  <p>
                    <strong>Terms and Conditions of <a href="https://bscpad.com/" target="_blank">https://bscpad.com/</a></strong><br />
                    <small>Last revised Date: 14<sup>th</sup> September 2021</small>
                  </p>
                </div>
                <>
                  <ol>
                    <li>
                      <b>Introduction</b>
                      <p>
                        These Terms and Conditions (herein referred to as <b>"Terms"</b>) govern
                        the use and the conditions of{" "}
                        <a href="https://bscpad.com/">https://bscpad.com/</a> (herein referred
                        as <b>"Website"</b>), and the Services provided by BSCPAD DIGITAL
                        Limited (herein referred to as <b>"Company"</b> or <b>"We"</b> or{" "}
                        <b>"Us"</b>), a company incorporated and registered under the laws of
                        British Virgin Islands. These Terms constitute a binding and enforceable
                        legal contract between the Company and its Affiliate and subsidiaries
                        worldwide and you, an end user of the Services (herein referred to as{" "}
                        <b>"You"</b> or <b>"User"</b>) in relation to the Services. You and the
                        Company are referred to separately as <b>"Party"</b> and collectively as{" "}
                        <b>"Parties"</b>.
                      </p>
                      <p>
                        By accessing, registering, using, or clicking on the Services, and
                        information made available by the Company via the Website, you hereby
                        accept and agree to all the Terms set forth herein.
                      </p>
                      <p>
                        You are strongly advised to carefully read these Terms as well as the
                        provisions detailed in our Privacy Policy prior to using the Website and
                        our Services. By using the Website and the Services in any capacity, you
                        agree that: (i) You have read and familiarized yourself with these
                        Terms; (ii) You understand these Terms; and (iii) You agree to be bound
                        by these Terms when using the Website. If You do not agree to these
                        Terms, please do not access or use the Website and the Services.
                      </p>
                      <p>
                        We reserve the right to modify or amend these Terms, the Website, or any
                        content on either one of the platforms from time to time, including for
                        security, legal, or regulatory reasons, as well as to reflect updates or
                        changes to the services or functionality of the Website. You are advised
                        to check these Terms periodically to ensure that you are cognizant of
                        the current versions and comply with them. Users of the Website and the
                        Services are bound by these changes which will take immediate effect
                        after the revised versions of these Terms have been published on the
                        Website or the relevant mobile application. Through your continued use
                        of or interaction with the Website, the Services, tools, and information
                        made available on these platforms, you hereby agree to be bound by the
                        provisions highlighted in the subsequent versions.
                      </p>
                      <p>
                        We will provide a notification on the Website specifying that changes
                        have been made to these Terms whenever they occur. By accepting the
                        notification, you accept that we have provided you with sufficient
                        notice of any changes. You should seek professional advice regarding any
                        possible legal requirements you must comply with in relation to the use
                        of the Website, the Service or the relevant tools provided by the
                        Company or the Partners.
                      </p>
                    </li>
                    <li>
                      <b>Definitions</b>
                      <p>
                        <b>"ADAPAD"</b> refers to the platform for Cardano Smart Chain Network,
                        which is a platform for retail investment into tokens offered via IDO.
                        Please find further details at{" "}
                        <a href="https://adapad.io/">https://adapad.io/</a>
                      </p>
                      <p>
                        <b>"Affiliate"</b> refers to in relation in any party, any other company
                        which, directly or indirectly, (i) is controlled by that party, (ii)
                        controls that party, or (iii) is under common control with that party,
                        and in respect of the Company, shall also include any fund, limited
                        partnership or other collective investment vehicle or other person which
                        is managed or advised by the Company's team.
                      </p>
                      <p>
                        <b>"AML"</b> refers to anti-money laundering.
                      </p>
                      <p>
                        <b>"Allocation Round"</b> refers to Round 1 of the Company fundraising
                        project tokens allocation, which will be managed by the BSCPad tiered
                        system.
                      </p>
                      <p>
                        <b>"Applicable Laws"</b> refers to acts, statutes, regulations,
                        ordinances, treaties, guidelines, and policies issued by governmental
                        organizations or regulatory bodies, including, but not limited to, the
                        governing law stipulated under Laws of the British Virgin Islands.
                      </p>
                      <p>
                        <b>"Bridge Service"</b> as defined in Clause 6.1.
                      </p>
                      <p>
                        <b>"BSCPAD Platform"</b> refers to the Company's Website, or platform
                        that facilitates token swaps, and where the Company's Project is
                        officially launched, and makes available its native Tokens (
                        <b>"Pool"</b>) to be distributed to the Company's user in the allocation
                        rounds in order to be swapped for other crypto assets.
                      </p>
                      <p>
                        <b>"BSCPAD Token"</b> refers to a blockchain - based token which is
                        issued, stored, transferred, transacted on the Binance Smart Chain
                        Network. Users need to hold BSCPAD Token to participate in any Services
                        on the Platform including Staking or pre-sale of Project selected for
                        the launchpad.
                      </p>
                      <p>
                        <b>"BNB"</b> refers to Binance Cryptocurrency which is required to pay
                        transaction fees on the Binance Smart Chain network.
                      </p>
                      <p>
                        <b>"BRIDGE"</b> refers to the service provided by the Company and its
                        Affiliate in Clause 6.
                      </p>
                      <p>
                        <b>"BUSD"</b> refers to a stable coin issued for the Binance ecosystem
                        and backed by the U.S. Dollar having a pegged value at 1 BUSD = $1.00
                        USD.
                      </p>
                      <p>
                        <b>"CFT"</b> refers to Combating the Financing of Terrorism.
                      </p>
                      <p>
                        <b>"Confidential Information"</b> refers to any non-public, proprietary
                        information or documents of or related to the User or the Company
                        (whether in writing, orally or by any other means) by or on behalf of
                        the User to the Company and which if disclose in tangible or intangible
                        form is marked confidential (including in visual, oral, or electronic
                        form) relating to Us or any other User that was previously a User, which
                        is provided or disclosed through the Company (or to any employees or
                        agents) in connection with the use or participate in the services.
                      </p>
                      <p>
                        <b>"Content"</b> refers to all content generated by the Company,
                        including logos, identifying marks, images, illustrations, designs,
                        icons, photographs, videos, text, any written or multimedia materials,
                        services, advertisements, software, code, data, files, archives,
                        folders, or available downloads on the BSCPAD Platform.
                      </p>
                      <p>
                        <b>"Cookie"</b> refer to the small text files that are placed on your
                        computer by the Website that you visit. They are widely used in order to
                        make Websites work, or work more efficiently, as well as to provide
                        information to the owner of the site.
                      </p>
                      <p>
                        <b>"CrossSwap"</b> refers to the cross chain swap designed to unify the
                        trading service on one platform. Please find further details at{" "}
                        <a href="https://crossswap.com/" target="_blank">
                          https://crossswap.com/
                        </a>
                        .
                      </p>
                      <p>
                        <b>"CrossWallet"</b> refers to the non-custodian wallet service that
                        holds any digital asset you prefer to hold, trade, or send, from tokens
                        to NFTs, which it works seamlessly move between different blockchain and
                        works on any device, either mobile or web. Please find further details
                        at{" "}
                        <a href="https://crosswallet.app/" target="_blank">
                          https://crosswallet.app/
                        </a>
                        .
                      </p>
                      <p>
                        <b>"ETHPAD"</b> refers to the platform for Ethereum Smart Chain Network,
                        which is a platform for retail investment into tokens offered via IDO.
                        Please find further details at{" "}
                        <a href="https://ethpad.network/" target="_blank">
                          https://ethpad.network/
                        </a>
                        .
                      </p>
                      <p>
                        <b>"FCFS Round"</b> refers to First Come First Serve Round or Round 2 of
                        the BRCPAD fundraising project token allocation for the unsold IDO
                        project tokens left from the Allocation Round.
                      </p>
                      <p>
                        <b>"IDO"</b> refers to Initial DEX Offerings or Initial Decentralized
                        Exchange Offerings.
                      </p>
                      <p>
                        <b>"KCCPAD"</b> refers to the platform for KuCoin Community Chain
                        Network, which is a platform for retail investment into tokens offered
                        via IDO. Please find further details at{" "}
                        <a href="https://kccpad.io/" target="_blank">
                          https://kccpad.io/
                        </a>
                        .
                      </p>
                      <p>
                        <b>"KCCSwap"</b> refers to the decentralized exchange service for
                        instantly swap token, crypto token, utility token with no requirement of
                        account registration and the service provider does not hold your funds
                        when you participate in the swap service, you have 100% ownership of
                        your crypto. Please find further details at{" "}
                        <a href="https://kccswap.io/" target="_blank">
                          https://kccswap.io/
                        </a>
                        .
                      </p>
                      <p>
                        <b>"Partners"</b> refers to the Company, ETHPAD, KCCPAD, TRONPAD,
                        ADAPAD, NFTLaunch, CrossWallet, CrossSwap, KCCSwap, and WagyuSwap.
                      </p>
                      <p>
                        <b>"Prohibited Jurisdictions"</b> specifically refer to the United
                        States of America (including its territories, and dependencies, and any
                        state of the United States), Albania, Barbados, Botswana, Burkina Faso,
                        Cambodia, Democratic People's Republic of Korea, Haiti, Iran, Jamaica,
                        Morocco, Myanmar (Burma), Nicaragua, Pakistan, Panama, Philippines,
                        Senegal, South Sudan, Syria, Thailand, Uganda, and Yemen, Zimbabwe.
                      </p>
                      <p>
                        <b>"Project"</b> refers to the Company’s project being launched for
                        Initial Decentralized Offering (‘IDO’) event on BSCPAD Platform.
                      </p>
                      <p>
                        <b>"Services"</b> refer to the services provided to the Users through
                        the Website, which include the accessibility to the new IDO token
                        offered from each IDO fundraising project, staking service and other
                        relevant services available through the Platform and all free trials and
                        beta services made available by the Company, which may be subject to
                        periodic revision.
                      </p>
                      <p>
                        <b>"Staking or Stake"</b> refers to the staking or deposit service in
                        which the Users can delegate or deposit the User’s BSCPAD Token in
                        exchange for a share of Net Staking Rewards on the Platform.
                      </p>
                      <p>
                        <b>"TRONPAD"</b> refers to the platform for Tron Smart Chain Network,
                        which is a platform for retail investment into tokens offered via IDO.
                        Please find further details at{" "}
                        <a href="https://tronpad.network/" target="_blank">
                          https://tronpad.network/
                        </a>
                        .
                      </p>
                      <p>
                        <b>"NFTLaunch"</b> refers to the platform for deflationary NFT Launchpad
                        which is the first platform to offer zero gas wars, fair distribution
                        and free NFT airdrops of new and upcoming NFT projetcs. Please find
                        further details at{" "}
                        <a href="https://nftlaunch.network/" target="_blank">
                          https://nftlaunch.network/
                        </a>
                        .
                      </p>
                      <p>
                        <b>"WagyuSwap"</b> refers to the Decentralized Exchange build on Velas
                        Blockchain, an entirely new blockchain adapted from Solana which
                        guaranteed full EVM compatibility. Please find further details at{" "}
                        <a href="https://wagyuswap.app/" target="_blank">
                          https://wagyuswap.app/
                        </a>
                        .
                      </p>
                      <p>
                        <b>"Wallet(s)"</b> refers to a BEP-20 wallet which must be compatible
                        with the Binance Smart Chain Network, such as MetaMask or Trust wallet.
                      </p>
                    </li>
                    <li>
                      <b>General Provisions</b>
                      <ol>
                        <li>
                          Contractual Relationship
                          <p>
                            These Terms constitute a valid and binding agreement between You and
                            the Company. The binding obligations stipulated in these Terms are
                            enforceable.
                          </p>
                        </li>
                        <li>
                          Revision and Amendments
                          <p>
                            The Company reserves the right to revise, amend, or update any
                            clauses and provisions stipulated in these Terms in its sole
                            discretion at any time. The Company will notify the revision or
                            amendment of such clauses or provisions by updating these Terms and
                            specify the 'Last Revised Date' displayed on a page of Terms and
                            Conditions. Any revisions and updates on these Terms will be
                            automatically effective upon their publication on the Platform,
                            which includes the Website. Therefore, your continuity of accessing
                            or using the BSCPAD Platform, and/or Services will be deemed that
                            you agree to have read, understood, and accepted all revised terms
                            and provisions. If you do not agree on any revised or updated terms
                            and provisions, you should immediately stop accessing the Website or
                            using our the Services. You are encouraged to frequently and
                            carefully review these Terms to ensure that you understand these
                            Terms.
                          </p>
                        </li>
                        <li>
                          Privacy Policy
                          <p>
                            You acknowledge and confirm that you have read, understood, and
                            agreed to provisions stipulated in the Website's Privacy Policy,
                            which will explain how the Company treats your information and
                            protects your privacy when accessing or using the BSCPAD Platform.
                          </p>
                          <p>
                            By using BSCPAD Platform, you hereby agree that the Company may
                            collect, use your information, and anonymised data pertaining to
                            your use of the Services for analytics, trends' identification, and
                            purpose of statistics to further enhance the effectiveness and
                            efficiency of the Services.
                          </p>
                          <p>
                            You hereby expressly authorise the Company to disclose any and all
                            information relating to you in the Company's possession to any law
                            enforcement or government officials upon the request by the court
                            order.
                          </p>
                        </li>
                        <li>
                          Links to and from the Website
                          <ol>
                            <li>
                              You may, through hypertext or other computer links, gain access
                              form the Website to websites operated or made available, or
                              otherwise licensed by persons other than us ("Third Party
                              Services"). Such hyperlinks are provided for your convenience.
                            </li>
                            <li>
                              A link from Third Party Services does not mean that the Company
                              endorses or approves the content on such website or does not mean
                              that the Company is an operator of that website. You understand
                              that you are solely responsible for determining the extent to
                              which you may use or rely upon any content at any other Third
                              Party Services websites which you have accessed from the Website.
                              the Company has no control over the content of these sites or
                              resources and accept no reasonability for them or for any loss or
                              damage that may arise from your use of them.
                            </li>
                            <li>
                              The Company assumes no responsibility for the use of, or inability
                              to use, any Third Party Services' software, other materials, or
                              contents posted and/or uploaded on such website and we will have
                              no liability whatsoever to any person or entity for any inaccuracy
                              or incompleteness of such third-party content. All intellectual
                              property rights in and to Third Party Services are property of the
                              respective third parties.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Disclaimer for Accessibility of the Website and the Services
                          <ol>
                            <li>
                              The Website merely facilitates the Users accessibility to the
                              Project tokens and does not provide you with any warranty or
                              representation whatsoever regarding its quality, value,
                              specification, fitness for the purpose, completeness or accuracy
                              of its technology or infrastructure of the tokens.
                            </li>
                            <li>
                              The Company will make all commercially reasonable attempts to
                              facilitate information about the Project on the BSCPAD Platform.
                              However, you hereby acknowledge that the Company does not
                              guarantee the accuracy, timeliness, or completeness of such
                              information, and does not provide any warranty in connection with
                              your use or reliance on such information. You agree that your use
                              of the Project information will be at all your own risk. The
                              Company will not be liable to you in any manner for the
                              termination, interruption, delay, or inaccuracy of any Project
                              information launched on the BCPAD Platform.
                            </li>
                            <li>
                              To the extent permitted by applicable laws, the Website and the
                              Company’s Services are provided on an ‘AS IS’ and ‘AS AVAILABLE’
                              basis. the Company does not warrant that the features, and
                              functions contained in the Website and the Services will satisfy
                              your preferences You hereby agree and acknowledge that your access
                              and use of the Website and the Services are at your own risk, and
                              you will be liable for any responsibility, consequences that may
                              arise out of or in connection with the usage or accessibility of
                              the Website and the Services. You expressly agree that the Company
                              will have absolutely no liability in this regard.
                            </li>
                            <li>
                              You hereby agree and acknowledge that the Website may contain
                              links to a third-party website or services that are not owned or
                              even controlled by the Company, thus the Company will hold no
                              responsibility for the content or services, goods, or activities
                              provided by such third-party website. You further agree that the
                              Company has no liability directly or indirectly for any damage,
                              loss, or consequence arising out of or in connection with the
                              usage or reliance of any content, material, services available
                              through the third-party website.
                            </li>
                            <li>
                              The Company reserves the right to limit the availability of the
                              Website to any person, geographic area, or jurisdiction we so
                              desire and/or terminate your access to and use of the Website and
                              the Services, at any time and in our sole discretion.
                            </li>
                            <li>
                              The Company may, at our sole discretion, impose limits or
                              restrictions on the use you make of the Website. Further, for
                              commercial, security, technical, maintenance, legal or regulatory
                              reasons, or due to any breach of these Terms, we may withdraw the
                              Website or your access to the Website and the Services at any time
                              and without notice to You.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Information
                          <p>
                            You acknowledge that you are solely responsible for any submissions
                            of all contents, remarks, suggestions, ideas, materials, feedbacks,
                            or other information, including bug reports in relation to the
                            Services provided through the Website including any submission to
                            our social media platforms such as Twitter and Telegram, and you,
                            not us, have full responsibility for such submissions, including
                            their accuracy, legality, reliability, appropriateness, originality,
                            and copyright. We shall reply on the information you have provided
                            and will not verify it. Notwithstanding the foregoing, we have the
                            right to refuse to post, remove, edit, or abridge any submission for
                            any reason and to freely use, copy, disclose, publish, display, or
                            exploit such submission as we deem necessary without any payment of
                            royalty, acknowledgement prior to consent, we may retain copies of
                            all information materials relevant to the Service.
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Intellectual Property</b>
                      <ol>
                        <li>
                          All present and future copyright, title, interests in and to the
                          Services, registered and unregistered trademarks, design rights,
                          unregistered designs, database rights and all other present and future
                          intellectual property rights and rights in the nature of intellectual
                          property rights that exist in or in relation to the use and access of
                          the Website and Services are owned by or otherwise licensed to the
                          Company. Subject to your compliance with these Terms, the Company
                          grants you a non-exclusive, non-sub license, and any limited license
                          to merely use or access the Website and the Services in the permitted
                          hereunder.
                        </li>
                        <li>
                          Except as expressly stated in these Terms, nothing in these Terms
                          should be construed as conferring any right in or license to BSCPAD’s
                          or any other third party’s intellectual rights.
                        </li>
                        <li>
                          If and to the extent that any such intellectual property rights are
                          vested in you by operation of law or otherwise, you agree to do any
                          and all such acts and execute any and all such documents as we may
                          reasonably request in order to assign such intellectual property
                          rights back to us.
                        </li>
                        <li>
                          You agree and acknowledge that all Website content must not be copied
                          or reproduced, modified, redistributed, used, created for derivative
                          works, or otherwise dealt with for any other reason without being
                          granted a written consent from the Company.
                        </li>
                        <li>
                          Third parties participating on the Website may permit the Company to
                          utilise trademarks, copyrighted material, and other Intellectual
                          Property associated with their businesses. The Company will not
                          warrant or represent that the content of the Website does not infringe
                          the rights of any third party.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Participation in the BSCPAD Services</b>
                      <ol>
                        <li>
                          In order to avail access to the Services or participate in the BSCPAD
                          Platform, you may be required to go through KYC/AML/CFT process as
                          requested by each IDO fundraising project. Please refer to the
                          Identification Verification through KYC/AML/CFT/AML Process in Clause
                          7 for further information.
                        </li>
                        <li>
                          In term of Staking, you acknowledge and agree that BACPAD provides
                          Staking service to Users for the purposes of delegating the User’s
                          BSCPAD Token in exchange for a share of Net staking rewards. You will
                          provide the staking instruction to the Company. Once the staking
                          instruction is received, the system will record the instruction and
                          you will not be able to cancel or edit the staking instruction. When
                          the BSCPAD Platform connect with your Wallet, you will see your tier
                          based on the numbers of BSCPAD Token held in your Wallet. The level of
                          tier will affect the numbers of IDO tokens that you will receive, and
                          the Company has neither responsibility nor liability in relation to
                          the allocation of IDO tokens. Since it will be entirely subject to the
                          IDO fundraising project creator.
                        </li>
                        <p>
                          BACPAD reserves the right to reject your participation in Staking, if
                          you fail to fulfil to satisfy the identity verification requirements
                          through the KYC/AML/CFT process or commit any other suspicious
                          activity while participating in the Services provided by the Company.
                        </p>
                        <li>
                          Tokens being stacked are limited to BSCPAD Token only. Once you
                          initiate staking, you will be put in the each Allocation Round of IDO
                          fundraising project tokens event. The IDO tokens will be distributed
                          to you based on the level of the tier you are classified. In the
                          conclusion of the Allocation Round of IDO, you will be able to join
                          the FCFS Round for purchasing the unsold IDO tokens.
                          <p>
                            You hereby acknowledge and agree that the estimated IDO tokens or
                            reward yield displayed on the BSCPAD Platform for each IDO
                            fundraising project is an estimation. The actual amount of IDO
                            tokens reward you will receive may not match or may lower than your
                            estimation which will be subject to the reward compression or
                            expression. You further hereby acknowledge that the Company cannot
                            promise or guarantee the certain amount of the reward receiving from
                            staking your BSCPAD Tokens in order to engage in the IDO fundraising
                            project event. You shall to go through, and study the medium article
                            of each IDO fundraising project and fully understand the significant
                            information and conditions prior to participate such IDO event such
                            as the allocation date, the underlying technology, the features and
                            functions of IDO project tokens.
                          </p>
                        </li>
                        <li>
                          In the event that you wish to unstake/withdraw your BSCPAD Token and
                          gain the reward, you will provide the unstaking instruction to the
                          BSCPAD Platform. You acknowledge and agree that when you unstake your
                          BSCPAD Token, the delivery of such BSCPAD Token into your wallet will
                          take 7 days and the timing may vary based on time of day of the
                          unstake/withdraw instruction and execution. The amount of your
                          unstaking BSCPAD Token will not count towards your tier level for
                          upcoming IDO fundraising projects.
                          <p>
                            The Company will levy a withdrawing fee or minimum transaction in
                            the form of BNB amount, which will vary and be subject to the
                            amendment only at its sole discretion of the Company periodically.
                            The Company will not be liable for any loss caused or alleged caused
                            by timing differences and economic lost associated with the actual
                            delivery of the BSCPAD Token.
                          </p>
                        </li>
                        <li>
                          The Company reserves the right to amend any terms related to any
                          specific Staking program at any time in its sole discretion. The
                          Company will not be liable for any losses due to your misunderstanding
                          of the Staking program terms and changes to such term's rules.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Bridge Service</b>
                      <ol>
                        <li>
                          Upon completion of the registration and identity verification for your
                          Wallet, You may use services made available by the Partners, including
                          but not limited to, staking, and accessing to other information
                          released by the Company, participating in any launchpad, token swaps,
                          bridge, etc., in accordance with the provision of the bridge function
                          (<b>"Bridge Service"</b>).
                          <p>
                            Under the bridge function, You will be required to conduct the
                            following provisions for the purpose of double verification and
                            convenience crossing between two platforms:
                          </p>
                          — Connect your Wallet (by clicking the button shown on the Website)
                          with our Partner's service; and
                          <br />— Synchronize the Company KYC/AML/CFT with our Partners;
                          <p>
                            You hereby acknowledge and agree that the Company will not be liable
                            for any damage, loss, consequence, or devaluation of the assets held
                            in any of your Wallet under the provision of Partner's service,
                            which may occur as a result or in connection with any matter related
                            to disruptive function, broken internet system or any failure.
                          </p>
                        </li>
                        <li>
                          In term of KYC/AML/CFT synchronize, you understand and acknowledge
                          that you agree to share your KYC/AML/CFT information among our
                          Partners for the purpose of identity verification through KYC/AML/CFT
                          process. Therefore, you hereby agree that when you use the Company’s
                          synchronize service, you will inform the Company for any KYC/AML/CFT
                          information changed (if any).
                        </li>
                        <li>
                          Upon sending an instruction of the Company’s service for KYC/AML/CFT
                          synchronize, your account will be immediately updated to reflect the
                          KYC/AML/CFT profile, and your KYC/AML/CFT profile will be included in
                          our Partners’ data to match the identity verification through
                          KYC/AML/CFT requirement. Once the instruction is executed, your
                          account will be updated to reflect that the instruction has been fully
                          match and passed the KYC/AML/CFT verification and you are ready to
                          experience the Company’s service. The instruction will remain
                          uncompleted until it is fully filled with the Wallet address. To
                          provide a Wallet address, you authorized our Partners to confirm and
                          temporarily control the data transfer to the Company.
                        </li>
                        <li>
                          You are only allowed one time to synchronize KYC/AML/CFT to any
                          launchpad provided by Partners. Double check the address input in the
                          box, whether it is the address showing in your Wallet. If you need to
                          synchronize again or update any information, please contact the
                          Company’s supporting team, details provided in Clause 16.
                        </li>
                        <li>
                          Unless otherwise specified by the Company, to use the Bridge Service,
                          you must complete the identification verification through KYC/AML/CFT
                          process or passed the KYC/AML/CFT synchronized from any Partners for
                          the BSCPad’s account. You acknowledge and agree that:
                          <br />
                          <br />
                          a. you fully understand the swapping process, including but not
                          limited to the risk or major fluctuation of digital asset in swapping,
                          and the risk of exacerbated adverse outcome when leverage is used;
                          <br />
                          b. you have sufficient technological knowledge and experience and the
                          capacity to understand the process and agree to independently assume
                          all the risks arising from the Bridge Service; and
                          <br />
                          c. you agree and authorize the Company to take various reasonable
                          measures at its discretion (including but not limited to blocking or
                          freezing the swapping requests under specific circumstances) in
                          accordance with the Company's decision to protect the legitimate
                          interests of you, the Company itself and other users.
                        </li>
                        <li>
                          Prior to conducting the Bridge Service for swap your asset, in
                          accordance with type of asset swapping, you shall provide the amount
                          for swapping that shall comply with the daily limit (show on the
                          BRIDGE page). Unless otherwise specified by the Company, to use Bridge
                          Service, You agree to keep enough asset in your Wallet, as required by
                          engaging in the swapping process. Failure to keep enough assets may
                          result in cancellation of the swapping request.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Identity Verification through KYC/AML/CFT Process</b>
                      <ol>
                        <li>
                          As a software development company, the Company has no role in
                          enforcing KYC/AML/CFT by default, however, the Company has a mandatory
                          requirement for KYC/AML/CFT identification verification tools for the
                          IDO fundraising companies using the Company to enforce on their users.
                          The Company is implementing KYC/AML/CFT tools into the Company’s
                          launchpad through{" "}
                          <a href="https://kycaid.com/" target="_blank">
                            https://kycaid.com/
                          </a>
                          .
                        </li>
                        <li>
                          The use of KYC/AML/CFT tools on the Company by IDO fundraising
                          companies using the Services on BSCPAD Platform is not the discretion
                          of said entitles, and they must require You to complete KYC/AML/CFT
                          verification process before participating in any Projects.
                        </li>
                        <li>
                          Although the Company makes no warranty as to the merit, legality, or
                          juridical nature of any IDO token, we nonetheless understand the need
                          of the Project to require KYC/AML/CFT verification on their token sale
                          participants of Users. Therefore, the Company reserves the right:
                          <ol>
                            <li>
                              at any time, to ask for your personal information, Name-Surname,
                              Birthday,-e- mail address, nationality, location, government
                              identification number (Identification Card/Passport number and
                              Date of Identification Card/Passport issuing), telegram username,
                              BSCPad’s Wallet address, and any KYC/AML/CFT documentation with
                              the liveness test that it deems necessary to determine the
                              identity and location of a User, and reserves the right to
                              restrict Service and payment until the User’s identity is
                              sufficiently determined;
                            </li>
                            <li>
                              The liveness test shall require you to take a photo of your
                              government identification with your phone or camera. and then take
                              a photo of (i.e., a selfie of your face) holding your ID document
                              and a piece of paper you wrote on next to your face (not covering
                              your face), in which you shall write down the (1) BSCPad, (2) the
                              current date, and (3) the last 4 characters of your Wallet
                              address”. If you have any questions, please find detail about
                              KYC/AML/CFT process here:{" "}
                              <a
                                href="https://bscpad.medium.com/bscpad-kyc-process-16e6a5557138"
                                target="_blank"
                              >
                                https://bscpad.medium.com/bscpad-kyc-process-16e6a5557138
                              </a>
                              .
                            </li>
                            <li>
                              to share the submitted KYC/AML/CFT information and documentation
                              to the third parties to verify the authenticity of the submitted
                              information, and the end user (you) giving the consent to share
                              such information by using the Services; and
                            </li>
                            <li>
                              to reject the use of the Services that the Company has the
                              reasonable ground to believe that they are found to be in
                              violation of relevant and applicable AML/CFT laws and regulations,
                              and to cooperate with the competent authorities or any
                              investigation when and if necessary upon the valid request by the
                              court order.
                            </li>
                          </ol>
                        </li>
                        <li>
                          The Company expressly prohibits and rejects the use of the Service for
                          any form of illicit activity, including money laundering, terrorist
                          financing or trade sanctions violations, consistent with various
                          jurisdictions' laws, regulations and norms. To that end, the Services
                          are not offered to individuals or entities on any Politically Exposed
                          Persons ("PEP") lists, or subject to any United States, European
                          Union, or other global sanctions or watch lists. By using the
                          Services, you represent that you are not on any of such lists.
                        </li>
                        <li>
                          You fully acknowledge that your information and KYC/AML/CFT
                          documentation may be disclosed to government agencies or regulators
                          upon a valid request of the court order. Once you have decided to
                          participate in any Project and start staking your BSCPAD Token, you
                          must ensure that all information provided to the Company is complete,
                          accurate, and updated in a timely manner. The Company will rely on the
                          information you provided and should there be any reasonable grounds
                          for the Company to believe that the partial or the whole of your
                          information provided to us is incomplete, or incorrect, or outdated,
                          the Company reserves the right to send you a notice to demand
                          correction, or to delete such information directly, and, as the case
                          may be, to disable you to access to all or part of the Website and the
                          Services.
                        </li>
                        <li>
                          If the Company has a reasonable ground to believe that any User
                          transacts or use the Services by using digital currencies derived from
                          any suspicious illegal activities, the Company shall be entitled to
                          freeze, close, or delete the User’s accounts as necessary. The Company
                          will hold no liability to such users for any damage, or loss arising
                          out of or in connection with this manner herein. Please note that any
                          attempt to circumvent the Company’s measures set out in this Clause 7
                          will also result in a similar action.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>BSCPAD Tokens</b>
                      <ol>
                        <li>
                          The Company will issue a blockchain-based token called “BSCPAD Token”
                          on Binance Smart Chain Network. BSCPAD Token is classified as the
                          utility token designed to be used solely on the BSCPAD Platform or on{" "}
                          <a href="https://bscpad.com/">https://bscpad.com/</a> Website.
                        </li>
                        <li>
                          Users who stake BSCPAD Token in their Wallets will be eligible to
                          participate in a Project.
                        </li>
                        <li>
                          BSCPAD Token is not considered as security of any kind, and it also
                          does not carry any right to vote, manage or the right of ownership in
                          the BSCPAD Platform.
                        </li>
                        <li>
                          BSCPAD Token is neither money nor legal tender/currency, whether fiat
                          or otherwise, and it does not carry any value whether it is intrinsic
                          or assigned.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Misuse of the Website</b>
                      <ol>
                        <li>
                          In the event of any misuse and/or abuse of the Website or breach any
                          provision in these Terms, the Company reserves the right to block your
                          access to the Website and other Services until the matter is solved.
                        </li>
                        <li>
                          Use of the Website for transmission, publication or storage of any
                          material on or via the Website which is in violation of any applicable
                          laws or regulations or any third-party's rights is strictly
                          prohibited, including but not limited to the use of the Website or the
                          transmission, distribution, publication or storage any material on or
                          via the Website in a matter or for the purpose which infringes
                          copyright, trademark, trade secret or other intellectual property
                          rights, is obscene or harmful to minors or constitutes an illegal act
                          or harassment, is libellous or defamatory, violates any privacy or
                          data protections laws, is fraudulent or breaches any exchange control
                          laws.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>BSCPAD Services Representations and Warranties</b>
                      <ol>
                        <li>
                          Representations and Warranties You hereby agree to make the following
                          representations and warranties by accessing to the Website and/or
                          using the Services:
                          <ol>
                            <li>
                              You have full capacity and authority under the applicable laws to
                              agree and bind yourself to these Terms.
                            </li>
                            <li>You are eighteen years of age or older.</li>
                            <li>
                              You are not a citizen or a resident of the Prohibited
                              Jurisdictions, and you do not have any relevant connection with
                              any of the Prohibited Jurisdictions.
                            </li>
                            <li>
                              You are aware and agree to comply with all applicable domestic and
                              international laws, statutes, ordinances, and regulations
                              applicable to your use of the Website and the Services. Your use
                              of the Website and the Services are not for any unlawful or
                              illegal purposes, including but not limited to the usage against
                              the copyright laws and, AML/CFT laws.
                            </li>
                            <li>
                              You are the exclusive owner of BSCPAD Token and your other
                              cryptocurrencies, including BNB held in your Wallet. The tokens
                              maintained in your Wallets are not and will not be derived from
                              money laundering, terrorist financing, fraud, or any other illegal
                              activities under any applicable laws. You further hereby
                              acknowledge and agree that the Company will not be responsible for
                              actions taken by you that result in the loss or destruction of the
                              value of the tokens and rewards you hold in the Wallet.
                            </li>
                            <li>
                              You validly undertake any action or enter into any transaction
                              with regard to these Terms. You are solely responsible for the use
                              of the Website and the Services for all activities, or
                              transactions that occur on or through your User account on BCPAD.
                            </li>
                            <li>
                              You will provide only accurate, complete, and up-to-date
                              information and documents, if any, for the purposes of accessing
                              or using or participating the Company’s Services on the BSCPAD
                              Platform. You will further agree to put your effort to ensure that
                              the confidentiality of your personal or credential information,
                              including your wallet address is restricted, and safely maintained
                              to your device you use to access the Website.
                            </li>
                            <li>
                              You acknowledged and agree that if you lose access to the Wallet
                              that you connected with BSCPAD Platform, the Company will not be
                              able to help you recover the loss, or transfer of IDO tokens or
                              any BSCPAD Token back to your wallet. It will be your solely
                              responsibility to manage your BSCPad’s account, and the private
                              key.
                            </li>
                            <li>
                              You will be responsible for obtaining the data network access
                              necessary to use the Website. Your network's data and rates and
                              fees may apply if you access or use the Website from a
                              wireless-enabled device, and you will be responsible for such
                              rates and fees.
                            </li>
                            <li>
                              You understand and are aware of the risks associated with
                              accessing or using or participating in the Services, and you will
                              be fully liable at your own risk.
                            </li>
                            <li>
                              You are aware that you are subject to tax regulations in the
                              jurisdiction you reside in and will be fully responsible for
                              filling or reporting any taxes and paying them as required by the
                              Applicable Laws. The Company will not be liable to compensate you
                              for your tax obligations or advise you in relation to your tax
                              obligations. Any uncertainties and unpredictable matters in tax
                              legislation with respect to any tokens may expose you to any
                              unknown or unforeseeable tax implications associated with your
                              holding of tokens and the use the Services for which the Company
                              will have no liability. Moreover, you will not hold the Company
                              liable for any expenses or losses resulting from unknown or
                              unforeseeable tax implications.
                            </li>
                            <li>
                              You will not breach any of the provisions stipulated in these
                              Terms, the Privacy Policy, or any Applicable Laws in any relevant
                              jurisdictions.
                            </li>
                            <li>
                              You will not use the Website and the Services in one of any
                              following manners, except as expressly permitted in these Terms,
                              or at the Company's discretion.
                              <p>You will not:</p>
                              <p>
                                (a) infringe any propriety rights, including but not limited to
                                copyrights, patents, trademarks, or trade secrets of the
                                Company.
                              </p>
                              <p>
                                (b) use the Website or the Services to transmit any data or send
                                or upload any material or content that contains viruses, Trojan
                                horses, worms, time-bombs, keystroke loggers, spyware, adware,
                                or any other harmful programmes or similar computer code
                                designed to adversely affect the operation of the Website and/or
                                the Services.
                              </p>
                              <p>
                                (c) expressly or impliedly, use the Website and the Services in
                                the manner that is deemed unlawful, offensive, malicious,
                                threatening, libellous, defamatory, obscene, or otherwise
                                objectionable or violates these Terms, or any other party's
                                intellectual property.
                              </p>
                              <p>
                                (d) modify, make any back-up or archival copies of the Platform
                                or any part thereof including disassembling, and you will also
                                not adapt, hack the Website or modify another website to falsely
                                imply that it is associated with the Website falsely.
                              </p>
                              <p>
                                (f) crawl, scrape, or otherwise cache any content from the
                                Website, and you agree not to use any automated data collection
                                methods, data mining, robots, or scraping or any data gathering
                                methods of any kind on the Website.
                              </p>
                              <p>
                                (g) use the Website or any of its contents for advertising or
                                soliciting, for any other commercial, political, or religious
                                purpose, or to compete, either directly or indirectly with the
                                Company.
                              </p>
                            </li>
                            <li>
                              You will defend, indemnify, and not hold the Company, the
                              Partners, its Affiliate, each of their respective employees,
                              officers, directors, and representatives liable to and against any
                              claims, damages, losses, liabilities, costs, and expenses
                              (including reasonable attorney's fees), arising out of or relating
                              to any third-party claim concerning these Terms, or your use of
                              the Company’s Services in violation of these Terms and applicable
                              laws.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Each of the User’s Representations and warranties under this Clause 10
                          shall survive and continue to remain in full force and effect after
                          the termination and/or the expiration of these Terms.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Risk Disclosure</b>
                      <p>
                        By accessing the Website or using or participating in the Company
                        Services, you expressly acknowledge and assume the following risks:
                      </p>
                      <ol>
                        <li>
                          Risk of loss in value
                          <p>
                            Tokens or any digital currencies are not issued by any central banks
                            or national, supra-national, or quasi-national organizations. They
                            are also not backed by any hard assets or other credit. The value of
                            tokens or any digital currencies is affected by several factors,
                            including but not limited to, the total number of tokens or any
                            digital currencies in existence, the continued willingness of market
                            participants to exchange government-issued currency for tokens or
                            digital currencies , purchasers' expectations with respect to the
                            rate of inflation of fiat currencies, purchasers' expectations with
                            respect to the rate of deflation of cryptocurrencies, interest
                            rates, currency exchange rates, cyber theft of cryptocurrencies from
                            online digital wallet providers, or news of such theft from such
                            providers or individuals' digital wallets, investment and trading
                            activities of large investors, monetary policies of the governments,
                            trade restrictions, currency devaluations and revaluations,
                            regulatory measures, the global or regional political, economic or
                            financial events and situations. Thus, all these factors will affect
                            the value of tokens or digital currencies, which may result in the
                            permanent partial or total loss of the value of the Company, a
                            particular tokens or digital currency. No one will be obliged to
                            guarantee the liquidity or the market price of any of the BSCPAD
                            Token or digital currencies maintained into your Wallets. The
                            volatility and unpredictability of the value of tokens or digital
                            currencies relative to the government-issued currency may result in
                            a significant loss over a short period of time.
                          </p>
                        </li>
                        <li>
                          The regulatory regime governing tokens or digital currencies
                          <p>
                            The regulatory framework relating to tokens or digital currencies
                            remains unsettled, and any laws, regulations, or guidelines may be
                            significantly revised and amended which will materially and
                            adversely affect the value of tokens or digital currencies and your
                            services on <a href="https://bscpad.com/">https://bscpad.com/</a>{" "}
                            Website or BSCPAD Platform as follows.
                          </p>
                        </li>
                        <li>
                          Technical and system failure affected the obligations stipulated in
                          these Terms the Company may experience system failures, unplanned
                          interruptions in its Binance Smart Chain Network or services, hardware
                          or software defects, security breaches or other causes that could
                          adversely affect the Company’s infrastructure network, which includes{" "}
                          <a href="https://bscpad.com/">https://bscpad.com/</a> Website and
                          BSCPAD Platform.
                          <p>
                            The Company is unable to anticipate the occurrence of hacks,
                            cyber-attacks, mining attacks, including but not limited to
                            double-spend attacks, majority mining power attacks and
                            selfish-mining attacks, distributed denial of service attacks or
                            errors, vulnerabilities or defects on the Website, BSCPAD Token,
                            Users' Wallets or any technology, including but not limited to smart
                            contract technology. Also, the Company is unable to detect the hacks
                            as mentioned earlier, mining attacks, cyber-attacks, distributed
                            denials of service errors vulnerabilities, or defects in a timely
                            manner and does not have sufficient resources to efficiently cope
                            with multiple service incidents happening simultaneously or in rapid
                            succession.
                          </p>
                          <p>
                            In addition, the Company's network or the services could be
                            disrupted by numerous events, including natural disasters, equipment
                            breakdown, network connectivity downtime, power losses, or even
                            intentional disruptions of its services, such as disruptions caused
                            by software viruses or attacks by unauthorized users, some of which
                            are beyond the Company's control. Although the Company has taken
                            steps and used its best endeavour against malicious attacks on its
                            appliances or its infrastructure, which are critical for the
                            maintenance of the BSCPAD Platform and the Services, there can be no
                            assurance that cyber-attacks, such as distributed denials of the
                            Service, will not be attempted in the future, and that the Company's
                            enhanced security measures will be effective. Any significant breach
                            of the Company's security measures or other disruptions resulting in
                            a compromise of the usability, stability and security of the
                            Company's network or the services, including the BSCPAD Platform,
                            may adversely affect BSCPAD Token.
                          </p>
                        </li>
                        <li>
                          The Company will have no liability for any delay, error, interruption,
                          or failure to perform any obligation under these Terms where the delay
                          or failure is directly or indirectly resulting from any causes beyond
                          the Company's control, including, but not limited to:
                          <ol>
                            <li>Acts of God, nature, court or government;</li>
                            <li>
                              Failure or interruption of public or private telecommunication
                              networks, the failure of Binance Smart Chain Network,
                              communication channels or information systems;
                            </li>
                            <li>
                              Acts or omission of a party for whom the Company is not
                              responsible;
                            </li>
                            <li>
                              Delay, failure, or interruption in, or unavailability of,
                              third-party services; and
                            </li>
                            <li>
                              Strikes, lockouts, labour disputes, wars, terrorist acts and
                              riots.
                            </li>
                          </ol>
                        </li>
                        <li>
                          YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES AND THE WEBSITE
                          IS COMPLETELY AT YOUR OWN RISK. THIS CLAUSE IS NOT EXHAUSTIVE AND DOES
                          NOT DISCLOSE ALL THE RISKS ASSOCIATED WITH DIGITAL CURRENCIES AND THE
                          USE OF SERVICES. THEREFORE, YOU ARE RECOMMENDED TO CAREFULLY CONSIDER
                          WHETHER SUCH USE IS SUITABLE FOR YOU IN LIGHT OF YOUR JUDGEMENT,
                          CIRCUMSTANCES, AND FINANCIAL POSITION.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Limitation of Liability</b>
                      <ol>
                        <li>
                          NOTWITHSTANDING ANY PROVISIONS WITHIN THESE TERMS, IN NO EVENT WILL
                          THE COMPANY, ITS PARTNERS ITS AFFILIATE, OR ITS EMPLOYEES, AGENTS,
                          OFFICERS, OR DIRECTORS BE LIABLE TO THE USER FOR ANY INCIDENTAL,
                          SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, OR CONSEQUENTIAL DAMAGES OF
                          ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH
                          YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT,
                          ANY CONTENT ON THE WEBSITE OR OTHER SUCH WEBSITES, OR ANY OTHER
                          SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, OR OTHER SUCH
                          WEBSITES, INCLUDING, BUT NOT LIMITED TO, LOST REVENUE, LOST PROFITS,
                          LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
                          GOODWILL, LOSS OF DATA, LOSS OF YOUR CREDENTIAL INFORMATION, LOSS OR
                          INTERRUPTION OF TECHNOLOGY, LOSS OF USE OF SERVICE OR EQUIPMENT, EVEN
                          IF THE USER WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND
                          WHETHER ARISING UNDER A THEORY OF CONTRACT, TORT, STRICT LIABILITY OR
                          OTHERWISE. THE COMPANY WILL ALSO HAVE NO LIABILITY FOR ANY EMERGENCY
                          AND/OR UNFORESEEABLE INCIDENTS RELATED TO YOUR USE OF THE SERVICES ON
                          THE BSCPAD Platform SUCH AS STOLEN PRIVATE KEY, OR HACKED ACCOUNTS.
                        </li>
                        <li>
                          Except as expressly provided in these Terms, and to the maximum extent
                          permitted by any Applicable Laws, we disclaim all other
                          representations or warranties, express or implied, made to you, your
                          affiliates, or any other person, including, without limitation, any
                          warranties regarding the quality, suitability, merchantability,
                          fitness for a particular purpose or otherwise (regardless of any
                          course of dealing, custom or usage of trade) of any service provided
                          incidental to the Services under these Terms.
                        </li>
                        <li>
                          In no event will our aggregate liability for any loss or damage that
                          arises in connection with the Services exceed the purchase amount you
                          paid to participate in the Service, if any, during a twelve (12) month
                          period immediately preceding the event that gave rise to the claim for
                          liability. The preceding limitations of liability will apply to the
                          fullest actual amount you paid to participate in or access in the
                          Service.
                        </li>
                        <li>
                          Except as expressly provided in these Terms, and to the fullest extent
                          permitted by any Applicable Laws, the Company, its Affiliate, and its
                          related parties each disclaim all liability to you for any loss or
                          damage arising out of or due to:
                          <ol>
                            <li>
                              your use of, inability to use, or availability or unavailability
                              of the Services, including any Third Party Services made available
                              through the Services;
                            </li>
                            <li>
                              the occurrence or existence of any defect, interruption, deletion
                              of files, delays in the operation or transmission of information
                              to, from, or through the Services, communications failure, theft,
                              destruction or unauthorised access to BSCPAD’s records, programs,
                              services, server, or other infrastructure relating to the
                              Services;
                            </li>
                            <li>
                              the Services being infected with any malicious code or viruses; or
                            </li>
                            <li>
                              the failure of the Services to remain operational for any period
                              of time.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Indemnification</b>
                      <p>
                        You irrevocably undertake the responsibility of fully indemnifying and
                        holding harmless each of the Company, its Affiliate, licensors,
                        shareholders, officers, directors, managers, employees, and agents from
                        and against any and all losses, claims, actions, proceedings, damages,
                        demands, judgements, sums, liabilities, damages, costs, charges and
                        expenses, including, but not limited to, any reasonable attorney's fees
                        or penalties imposed by any regulatory authority, and reimbursements
                        arising out of or related to the following situations:
                      </p>
                      <ol>
                        <li>
                          Your use or any person using the Services on your behalf or
                          participation in accordance with the Services on the Website or the
                          BSCPAD Platform;
                        </li>
                        <li>Your breach of or our enforcement of these Terms;</li>
                        <li>
                          Any violations of Applicable Laws, regulation, or rights of any
                          third-party during your use or participate in the Service.
                        </li>
                      </ol>
                      <p>
                        If you are obligated to indemnify the Company, its Affiliate,
                        shareholders, licensors, officers, directors, managers, employees, and
                        agents, the Company will have the right, at our sole discretion, to
                        control any action or proceeding and to determine whether BSPAD wishes
                        to proceed, or settle, and if so, on what terms or provisions.
                      </p>
                    </li>
                    <li>
                      <b>Termination</b>
                      <ol>
                        <li>
                          These Terms will be immediately terminated by discontinuing your use
                          of or participate in the Services and you agree to terminate the
                          accessibility on the BSCPAD’s Platform.
                        </li>
                        <li>
                          These Terms can be suspended or terminated without a notice from the
                          Company if there is a reasonable ground for the Company to believe
                          that you have breached any of the terms or provisions stipulated in
                          these Terms, or if you do not comply with these Terms.
                        </li>
                        <li>
                          The termination of these Terms will not prevent the Company from
                          seeking remedies from you in the case where you have breached any
                          terms or provisions of these term before such termination. The Company
                          will not be liable to you or to any third party for any termination,
                          suspension, or modification of your access to the Services.
                        </li>
                        <li>
                          Any ongoing obligation to you as well as the provisions regarding (i)
                          BSCPAD' Intellectual Property, (ii) No solicitation or Offering, (iii)
                          Indemnification, (iv) Limitation of liability, and (v) any other
                          provisions designed to survive, will survive any termination or
                          expiration of these Terms for any reason.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>No Financial and Legal Advice</b>
                      <p>
                        The Company is merely a technology platform, and is not your broker,
                        intermediary, agent, or legal advisor and has no fiduciary relationship
                        or obligation to you in connection with any decisions or activities
                        effected by you using the Website or the Services. No communication or
                        information provided to you by the Company is intended as or will be
                        considered or construed as, the solicitation of an offer to buy, the
                        investment advice, financial advice, legal advice, or any other sort of
                        advice. All Services, transactions, and Investments will be executed
                        automatically based on the parameters of your consideration. You will be
                        solely responsible for determining whether any Services, or investments
                        are suitable and match your interests according to your judgement,
                        objectives, circumstances, and risk tolerance. You will be solely
                        responsible for any losses or liabilities therefrom.
                      </p>
                      <p>
                        Before executing any transactions, purchasing BSCPAD Token or IDO tokens
                        on the BSCPAD Platform, you should consult with your independent
                        financial, legal, or tax professionals. The Company will not be liable
                        for the decisions you make to access and purchase through the Company.{" "}
                      </p>
                    </li>
                    <li>
                      <b>Notice/Announcement</b>
                      <p>
                        Any notice, requests, demands, and determinations for the Company under
                        these Terms (other than routine operational communications) shall be
                        sent to <a href="mailto:support@bscpad.com">support@bscpad.com</a> at{" "}
                        <a href="https://bscpad.com/">https://bscpad.com/</a>.
                      </p>
                    </li>
                    <li>
                      <b>
                        Governing Law, Resolving Disputes, Arbitration and Class Action Waiver
                      </b>
                      <p>
                        PLEASE READ THIS SECTION CAREFULLY AS IT INVOLVES A WAIVER OF CERTAIN
                        RIGHTS TO BRING LEGAL PROCEEDINGS, INCLUDING A CLASS ACTION.
                      </p>
                      <ol>
                        <li>
                          Notice of Claim and Dispute Resolution Period
                          <p>
                            Please contact the Company first. The Company will seek to address
                            your concerns without resorting to formal legal proceedings whenever
                            possible. If you have a dispute with the Company, you should contact
                            the Company, and a case number will be assigned. The Company will
                            attempt to resolve your dispute internally as soon as possible. The
                            parties will agree to negotiate in good faith to resolve the dispute
                            and discussions will remain confidential and subject to applicable
                            laws protecting settlement discussions from use as evidence in any
                            legal proceeding.
                          </p>
                        </li>
                        <li>
                          Agreement to Arbitrate
                          <p>
                            You and the Company agree that subject to paragraph 16.1 above, any
                            dispute, claim, or controversy between you and the Company that
                            arises in connection with, or relating in any way, to these Terms,
                            or to your relationship with the Company as a user of the Services
                            (whether by contract, tort, statute, fraud, misrepresentation, or
                            any other legal theory, and whether the claims arise during or after
                            the termination of these Terms) will be determined by a mandatory
                            final and binding individual arbitration rather than a class action,
                            except as set forth below under Exceptions to the Agreement to
                            Arbitrate. You and the Company further agree that the arbitrator
                            will have the exclusive power to rule on his or her jurisdiction,
                            including, without limitation, any objections with respect to the
                            existence, scope, or validity of the Agreement to Arbitrate, or to
                            the arbitrability of any claim or counterclaim. Arbitration is more
                            informal than a lawsuit in court. THERE IS NO JUDGE OR JURY IN
                            ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED.
                            There may be more limited discovery than in court. The arbitrator
                            must follow this agreement and can award the same damages and relief
                            as a court, including, if applicable, attorney fees, except the
                            arbitrator may not award declaratory or injunctive relief benefiting
                            anyone but the parties to the arbitration. The arbitration
                            provisions set forth in Clause 17 will survive termination of these
                            Terms.
                          </p>
                        </li>
                        <li>
                          Arbitration Rules
                          <p>
                            Any dispute arising out of or in connection with these Terms
                            including any question regarding its existence, validity or
                            termination, shall be referred to and finally resolved by
                            arbitration administered by the Singapore International Arbitration
                            Centre ("SIAC") in accordance with the Arbitration Rules of the
                            Singapore International Arbitration Centre ("SIAC Rules") for the
                            time being in force, which rules are deemed to be incorporated by
                            reference in these Terms. The seat of the arbitration shall be
                            Singapore. The Tribunal shall consist of one arbitrator. The
                            language of the arbitration shall be English.
                          </p>
                        </li>
                        <li>
                          Governing Law/Jurisdiction
                          <p>
                            The governing law of the arbitration will be that of the British
                            Virgin Islands.
                          </p>
                        </li>
                        <li>
                          Confidentiality
                          <p>
                            The parties agree that the arbitration will be kept confidential.
                            The existence of the arbitration, any non-public information
                            provided in the arbitration, and any submissions, orders or awards
                            made in the arbitration will not be disclosed to any non-parties
                            except the tribunal, the parties, their counsel, experts, witnesses,
                            accountants and auditors, insurers and reinsurers, and any other
                            person necessary to facilitate the arbitration. Notwithstanding the
                            preceding, a party may disclose information to the extent that
                            disclosure may be required to fulfil a legal duty, protect, or
                            pursue a legal right, or enforce or challenge an award in bona fide
                            legal proceedings. This confidentiality provision will survive the
                            termination of these Terms and any arbitration brought under these
                            Terms.
                          </p>
                        </li>
                        <li>
                          Class Action Waiver
                          <p>
                            You and the Company agree that any claims relevant to these Terms,
                            or your relationship with the Company will be brought against the
                            other party in arbitration on an individual basis only and not as a
                            plaintiff or class member in a purported class or representative
                            action. You and the Company further agree to waive any right for
                            such claims to be brought, heard, or arbitrated as a class,
                            collective, representative, or private attorney general action, to
                            the extent permissible by applicable laws. Combining or
                            consolidating individual arbitrations into a single arbitration is
                            not permitted without the consent of all parties involved.
                          </p>
                        </li>
                        <li>
                          The Company reserves the right to update, modify, revise, suspend, or
                          make future changes to Section 16.2 regarding the parties' Agreement
                          to Arbitrate, subject to applicable laws. You hereby consent and agree
                          that it is your responsibility to ensure that your understanding of
                          this Clause is up to date. Subject to applicable laws, your continued
                          use of your BSCPad’s account will be interpreted as your acceptance of
                          any modifications to Clause 16 regarding the parties' Agreement to
                          Arbitrate. You agree that if you object to the modifications to Clause
                          16, the Company may block access to your account pending closure of
                          your account. In such circumstances, these Terms prior to modification
                          will remain in full force and affect the pending closure of your
                          accessibility.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Miscellaneous</b>
                      <ol>
                        <li>
                          Severability
                          <p>
                            If any of the provisions in these Terms is found by a court of
                            competent authority to be invalid, void, unlawful or unenforceable
                            under any applicable laws, such unenforceability or invalidity will
                            not render these Terms unenforceable or invalid as a whole, and such
                            provisions will be deleted without affecting the remaining
                            provisions herein.
                          </p>
                        </li>
                        <li>
                          Variation of Terms
                          <p>
                            the Company has the right to revise these Terms at our sole
                            discretion at any time, and by using the Website or other BSCPAD’s
                            platform, you will be expected to review such Terms regularly to
                            ensure that you understand all provisions stipulated in these Terms.
                          </p>
                        </li>
                        <li>
                          Assignment
                          <p>
                            The Company will be allowed to assign, transfer, and subcontract Our
                            rights and/or obligations under these Terms without the need to
                            provide you any notification or acquire your consent. Nevertheless,
                            you will not be permitted to assign, transfer, or subcontract any of
                            your rights and/or obligations under these Terms.
                          </p>
                        </li>
                        <li>
                          Entire Agreement
                          <p>
                            These Terms, including the Privacy Policy and any rules contained on
                            the Website or others BSCPAD Platform, constitute the sole and
                            entire agreement between You and the Company with respect to your
                            use of the BSCPAD Platform, and supersedes other prior or
                            contemporaneous negotiations, discussions, agreements,
                            understandings, representations, and warranties, both written and
                            oral, between You and the Company with respect to such subject
                            matter.
                          </p>
                        </li>
                        <li>
                          No Third-Party Rights
                          <p>
                            Nothing in these Terms will be deemed to create any rights to any
                            creditors or other persons, not a party hereto. Moreover, these
                            Terms will not be construed, in any respect, to be a contract, in
                            whole or in part, for the benefit of any third parties.
                          </p>
                        </li>
                        <li>
                          Clickwrap
                          <p>
                            The Company may deliver the Service through electronic means such as
                            download links, graphical, Tools or other technologies for providing
                            the Service for users. The user interfaces to such electronic means
                            may require that the users agree to these Terms by checking a box,
                            clicking a button, or continuing with the Service. If user through
                            such action the user becomes a Party to these Terms. Such an action
                            of acceptance shall be sufficient to bind the users to the terms and
                            conditions herein these Terms.
                          </p>
                        </li>
                        <li>
                          Waiver
                          <p>
                            The failure of one Party to require the performance of any provision
                            will not affect that Party's right to require performance at any
                            time thereafter. At the same time, the waiver of one Party to seek
                            recovery for the other Party's violation of these Terms of any
                            provisions of applicable terms will not constitute a waiver by that
                            Party of any subsequent breach or violation by the other Party or of
                            the provision itself.
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUsePage;

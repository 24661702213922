import React, { useLayoutEffect, useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import $ from 'jquery';
import { useEffect } from "react";
import { getListEvents } from "../../redux/services/event.api";
import CalendarItemModal from "../../component/shared/CalendarItemModal";

const localizer = momentLocalizer(moment);

const CalendarPage = (props) => {
  const [eventDetail, setEventDetail] = useState(null);
  const [myEventsList, setMyListEvents] = useState([])

  useEffect(() => {
    getListEvents().then(result => {
      setMyListEvents(result.data)
    })
  }, [])

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }


  function selectEvent($event) {
    setEventDetail($event);
    window.setTimeout(() => {
      $('#btnViewDetail').trigger('click');
    }, 400);
    // console.log($event);
    // if ($event?.url)
    //   window.open($event?.project_url, '_blank');
  }

  const CustomEvent = ({ event }) => (
    <div className="d-flex justify-content-center" style={{ flexDirection: 'column' }}>
      {/* <div className="calendar-item-ido-cal">{event?.name}</div> */}
      <div className="calendar-item calendar-item-cal p-0 mb-0" style={{ maxHeight: '155px' }}>
        <a>
          <div className="calendar-item-icon h-auto text-center" style={{ borderRadius: 0 }}>
            <img src={event?.icon} alt="" />
          </div>
        </a>
      </div>
    </div>
  );

  const [width, height] = useWindowSize();
  return (
    <div className="pp-projects-page">
      <div className="py-4 mt-3">
        <div className="container">
          {/* <div className="section-text mb-5">
            <h2 className="section-title" style={{ textTransform: 'none' }}>IDO Calendar</h2>
          </div> */}
          <Calendar
            components={{
              event: CustomEvent
            }}
            showMultiDayTimes={false}
            style={{ minHeight: width > 1400 ? '1200px' : width > 1200 ? '1150px' : width > 1024 ? '1000px' : width > 992 ? '950px' : width > 768 ? '850px' : '460px' }}
            popup={true}
            localizer={localizer}
            culture="es_us"
            events={myEventsList}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={selectEvent}
          />
        </div>
      </div>
      <button type="button" className="d-none" id="btnViewDetail" data-bs-toggle="modal" data-bs-target="#calendarItemModal"></button>
      <CalendarItemModal data={eventDetail} />
    </div>
  );
};

export default CalendarPage;

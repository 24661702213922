

export const STATUS = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
};
export const connectorLocalStorageKey = 'connectorId';
export const ConnectorNames = {
  Injected : 'injected',
  BSC : 'bsc',
  WalletConnect : 'WalletConnect',
}
export const BSC_NETWORK_CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID ?? '56');
export const NETWORK_URL = 'https://bsc-dataseed1.defibit.io/';
export const NetworkContextName = 'NETWORK';

import React, { useEffect, useState, } from "react";
import { get } from "lodash";
import { ACTION_CONST, MESSAGES } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { helpers } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import { useSelectedProject, useWeb3Util } from "../../hook/useSate";

const InvestmentModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedProject = useSelectedProject();
  const web3Utils = useWeb3Util();
  const [projectName, setProjectName] = useState("")
  const [symbol, setSymbol] = useState("")
  const [amount, setAmount] = useState(0);
  const [enableBuyBtn, setEnableBuyBtn] = useState(false);

  useEffect(() => {
    if (selectedProject) {
      setProjectName(selectedProject.name)
      setSymbol(selectedProject.symbol);
    }
  }, [selectedProject]);

  const handleClickMax = () => {
    if (props.walletInfo.tokenBalance > 0 && props.allocationNumber >= props.walletInfo.tokenBalance) {
      document.getElementById("inputAmountSwap").value = helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 4);
      setAmount(helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 4))
      setEnableBuyBtn(true);
      return;
    }
    if (props.allocationNumber > 0 && props.allocationNumber < props.walletInfo.tokenBalance) {
      document.getElementById("inputAmountSwap").value = helpers.formatNumberDownRound(props.allocationNumber, 4);
      setAmount(helpers.formatNumberDownRound(props.allocationNumber, 4));
      setEnableBuyBtn(true);
      return;
    }
    setEnableBuyBtn(false);
  }

  const handleOnchangeAmount = (e) => {
    const amountInputValue = Number(e.target.value);
    if (0 < amountInputValue && amountInputValue <= props.allocationNumber
      && amountInputValue <= props.walletInfo.remainingAllocation
      && amountInputValue <= props.walletInfo.tokenBalance) {
      setEnableBuyBtn(true);
    } else {
      setEnableBuyBtn(false)
    }
    setAmount(amountInputValue)
    props.handleInputAmount(amountInputValue)
    return;
  }

  const handleBuyClick = () => {
    if (web3Utils && selectedProject) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      console.log("amount==>", Number(amount));
      web3Utils.buyTokens({
        contractAddress: selectedProject.contract,
        tokenAddress: selectedProject.tokenAddress,
        amount: Number(amount)
      }, (data) => {
        if (data.status == "BUY_SUCCESS") {
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          props.handleBuyClick()
          setEnableBuyBtn(false);
          document.getElementById("inputAmountSwap").value = 0;
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: MESSAGES.BUY_TOKEN_SUCCESS
          })
        }

        if (data.status == "BUY_FAIL") {
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: MESSAGES.BUY_TOKEN_FAIL
          })
        }
      }).catch(err => {
        dispatch({ type: ACTION_CONST.REQUEST_DONE })
        dispatch({
          type: ACTION_CONST.ALERT_FAILS,
          message: MESSAGES.BUY_TOKEN_FAIL
        })
        console.log(err);
      })
    }
  }

  return (
    <div
      className="modal fade"
      id="buyModal"
      tabIndex="-1"
      aria-labelledby="buyModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="buyModalLabel">{t('Join')} {projectName} {t('Pool')}</h5>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn-close me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div className="modal-body">
            <div className="mb-3 d-flex align-items-center">
              <label className="form-label mb-0 me-4">{t('Your balance')}: </label><h4 className="text-warning mb-0">{props?.tokenBalance} {props?.symbol}</h4>
            </div>
            <div className="mb-4 d-flex align-items-center justify-content-between" style={{ columnGap: '10px' }}>
              <span className="ps-0" style={{ border: 0, whiteSpace: 'nowrap' }}>{symbol} {t('amount')}:</span>
              <input type="number" id="inputAmountSwap" className="form-control ms-2 me-1"
                style={{ width: 'calc(100% - 12px)' }} defaultValue={0}
                autoFocus onChange={(e) => handleOnchangeAmount(e)} />
              <button style={{ borderRadius: '5px' }} className="btn btn-outline-primary" onClick={() => handleClickMax()}> {t('Max')}</button>
            </div>
            <div className="get-start mt-3 text-center mb-2 d-flex" style={{ columnGap: '10px' }}  >
              <button className="btn btn-primary w-100" disabled={!enableBuyBtn} onClick={() => handleBuyClick()} data-bs-dismiss="modal">
                {t('Join')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentModal;

import React from "react";
import { useTranslation } from "react-i18next";
import TierContainer from "../../component/TierContainer";
import {TIERED_SYSTEM} from '../../constants/values'



const TieredSystemComponent = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="pp-home-how-it-work pp-section pt-3 pb-5" id="how-it-work">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-8">
                            <div className="section-head">
                                <h2 className="title title-xl" data-aos="fade-up" data-aos-delay="100" title={t('Tiered System')}>{t('THE BSCPAD TIERED SYSTEM')}</h2>
                                <p data-aos="fade-up" data-aos-delay="200" className="mt-3">{t('BSCPad will showcase a fixed tier system based on the number of tokens staked. Lottery Tiers will share 20% of total raise and rest 80% of the raise is assigned for guaranteed allocation tiers based on the pool weights assigned.')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <TierContainer TIERED={TIERED_SYSTEM}/>
            </div>
        </>
    )

}


export default TieredSystemComponent;